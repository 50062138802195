import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

export const Background = styled('div')(({ theme }) =>
    mq({
        marginTop: ['70px', '127px', '124px'],
        width: '100%',
        borderRadius: ['50px', '144.5px 0px 200px', '200px 0px 300px'],
        background: theme.colors.secondaryColor_3,
        minHeight: '340px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    })
);

export const Body = styled('div')(() =>
    mq({
        position: 'relative',
        display: 'flex',
        flexDirection: ['column-reverse', 'row', 'row'],
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: ['100%', '72%', '65%'],
        padding: ['56px 0px', '70px 0px', '60px 0px'],
    })
);

export const ContentRight = styled('div')(() =>
    mq({
        width: '50%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        left: ['0%', '50%', '50%'],

        [mobileStyles]: {
            position: 'relative',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%',
        },
    })
);

export const ContentLeft = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: ['77%', '50%', '50%'],
        paddingRight: '20px',

        [mobileStyles]: {
            marginTop: '28px',
            alignItems: 'center',
            paddingRight: '0px',
        },

        '& button': {
            [mobileStyles]: {
                margin: '0 auto',
            },
        },
    })
);

export const Title = styled('h3')(({ theme }) => {
    return mq({
        ...theme.fonts.h3_headline,
        color: theme.colors.primaryText,
        width: '100%',
        minWidth: '387px',

        [tabletStyles]: {
            ...theme.fonts.h4_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.h5_headline,
            textAlign: 'center',
            padding: '0px 43px 0px 43px',
            marginTop: '40px',
        },
    });
});

export const Description = styled('div')(({ theme }) => {
    return mq({
        ...theme.fonts.body_2,
        color: theme.colors.primaryText,
        marginTop: '25px',

        '& p': {
            marginTop: '0px!important',
        },

        '& ul': {
            marginTop: '0px!important',
        },
    });
});
