import { CardsWrapper } from './SmallCards.styled';
import SmallCard from './SmallCard/SmallCard';
import type { SmallCards } from 'types/componentTypes';

const SmallCards: React.FC<SmallCards> = ({ cards }) => {
    const SmallCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <SmallCard
                key={card.id}
                {...card}
                lastItem={i === arrayLength - 1}
            />
        );
    });

    return <CardsWrapper className="cards">{SmallCards}</CardsWrapper>;
};

export default SmallCards;
