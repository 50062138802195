import { desktopStyles } from './../../types/displayTypeStyles';
import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

interface ComponentWrapperProps {
    $withTitle: boolean;
}

export const Wrapper = styled('div')(() =>
    mq({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: ['30px', '37px', '40px'],
        marginTop: ['60px', '70px', '110px'],
    })
);

export const ComponentWrapper = styled(
    'div',
    transientOptions
)<ComponentWrapperProps>(({ $withTitle }) =>
    mq({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: $withTitle ? '0px' : ['60px', '70px', '110px'],
    })
);

export const BlockTitle = styled('h3')(({ theme }) =>
    mq({
        ...theme.fonts.h3_headline,
        color: theme.colors.primaryText,

        [tabletStyles]: {
            ...theme.fonts.h4_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.h5_headline,
            padding: '0px 25px',
        },
    })
);

const divider = {
    content: "url('/img/verticalDivider.svg')",
    position: 'absolute',
    transform: 'scale(.85)',
};

export const BackgroundMultiple = styled('div')(({ theme }) =>
    mq({
        width: '95%',
        minHeight: '458px',
        backgroundColor: theme.colors.primaryColor,
        borderRadius: '100px 10px',
        padding: ['67px 22px', '45px 57px', '45px 225px'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',

        '& .multipleSection:nth-of-type(even)::before': {
            [desktopStyles]: {
                ...divider,
                left: '-110px',
                top: '-55px',
            },

            [tabletStyles]: {
                ...divider,
                left: '-70px',
                top: '-55px',
            },
        },

        '& .multipleSection:nth-of-type(odd)': {
            marginRight: ['0px', '140px', '224px'],
        },

        '& .multipleSection:last-of-type': {
            marginRight: '0px',
        },

        [mobileStyles]: {
            borderRadius: '50px 10px;',
            justifyContent: 'center',
            flexDirection: 'column',

            '& div:first-of-type': {
                marginTop: '0px',
            },

            '& div:last-of-type': {
                marginBottom: '0px',
            },

            '& .multipleSection::after': {
                left: '-17px',
                top: '118%',
                position: 'absolute',
                width: '110%',
                content: '""',
                border: `1px solid ${theme.colors.dividerColor}`,
                alignSelf: 'stretch',
            },

            '& .multipleSection:last-of-type::after': {
                display: 'none',
            },
        },
    })
);
