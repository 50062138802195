import {
    AccordionBody,
    AccordionContainer,
    AccordionTitle,
    Description,
    MainContainer,
    MainTitle,
    StyledAccordion,
    StyledRichDescription,
    StyledRichTitle,
} from './CustomAccordion.styled';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RichText from 'components/RichText';
import { useState } from 'react';
import { theme } from 'theme/theme';
import GloProsIcon from 'components/SVGIcons/GloProsIcon';

const AccordionSummaryStyles = (expandedIndex: number, index: number) => {
    return {
        borderRadius: '50px !important',
        boxShadow: '0px 0px 10px 0px rgba(17, 24, 33, 0.20) !important',
        border: 'none',
        padding: '20px',
        background:
            expandedIndex === index ? theme.colors.primaryColor_4 : 'white',

        '& .MuiAccordionSummary-contentGutters': {
            margin: 0,
        },

        '& .Mui-expanded': {
            margin: '0 !important',
        },
    };
};

interface AccordionProps {
    value: {
        title: string;
        description: string;
        sections: Array<{
            type: string;
            value: Array<{ type: string; value: string; id: string }>;
            id: string;
        }>;
        id: string;
    };
}

const CustomAccordion: React.FC<AccordionProps> = ({ value }) => {
    const [expandedIndex, setExpandedIndex] = useState<number>(-1);

    const handleAccordionChange = (i: number) => {
        setExpandedIndex((prevIndex) => {
            if (prevIndex === i) {
                return -1;
            } else {
                return i;
            }
        });
    };

    return (
        <MainContainer>
            <MainTitle>{value.title}</MainTitle>
            <Description>
                <RichText value={value.description} />
            </Description>
            <AccordionContainer>
                {value.sections.map((el, i) => {
                    return (
                        <StyledAccordion
                            key={i}
                            expanded={expandedIndex === i}
                            onChange={() => handleAccordionChange(i)}
                            TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={
                                    expandedIndex === i ? (
                                        <RemoveIcon />
                                    ) : (
                                        <AddIcon />
                                    )
                                }
                                aria-controls="panel1a-content"
                                sx={AccordionSummaryStyles(expandedIndex, i)}>
                                <AccordionTitle>
                                    <GloProsIcon width={33} height={33} />
                                    <StyledRichTitle
                                        value={
                                            el.value.find(
                                                (item) => item.type === 'title'
                                            )?.value || ''
                                        }
                                    />
                                </AccordionTitle>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    marginTop: '20px',
                                }}>
                                <AccordionBody>
                                    <StyledRichDescription
                                        value={
                                            el.value.find(
                                                (item) => item.type === 'body'
                                            )?.value || ''
                                        }
                                    />
                                </AccordionBody>
                            </AccordionDetails>
                        </StyledAccordion>
                    );
                })}
            </AccordionContainer>
        </MainContainer>
    );
};

export default CustomAccordion;
