import { Review } from 'types/componentTypes';
import {
    TypeText,
    Slide,
    CardWrapper,
    Circle,
    Type,
    SlideContent,
    BodyContent,
    Title,
    PosAndCompany,
    Position,
    Company,
    ImgWrapper,
    Text,
} from './CardFeedback.styled';
import useApiUrl from 'hooks/useApiUrl';
import Image from 'next/image';

interface CardFeedbackProps {
    review: Review;
}

const CardFeedback: React.FC<CardFeedbackProps> = ({ review }) => {
    const reviewValue = review.value;
    const apiUrl = useApiUrl();

    return (
        <CardWrapper>
            <Circle $type={reviewValue.label} />
            <Type $type={reviewValue.label}>
                <TypeText $type={reviewValue.label}>
                    {reviewValue.label}
                </TypeText>
            </Type>
            <Slide>
                <SlideContent>
                    <ImgWrapper>
                        <Image
                            src={apiUrl + reviewValue.photo}
                            width="200px"
                            height="210px"
                            objectFit="cover"
                            style={{ borderRadius: '200px 15px 200px 200px' }}
                        />
                    </ImgWrapper>

                    <BodyContent>
                        <Title>{reviewValue.fullName}</Title>
                        <PosAndCompany>
                            <Position>{reviewValue.jobTitle}</Position>
                            <Company> | {reviewValue.companyName}</Company>
                        </PosAndCompany>
                        <Text>{reviewValue.description}</Text>
                    </BodyContent>
                </SlideContent>
            </Slide>
        </CardWrapper>
    );
};

export default CardFeedback;
