import { ActionButton } from 'components/Cards/Cards.shared.styled';
import CustomLink from 'components/CustomLink';
import RichText from 'components/RichText';
import { ButtonCard, OrdinaryCard } from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';
import {
    CardWrapper,
    CardBody,
    Title,
    DescriptionWrapper,
    ButtonCardWrapper,
    MainContentWrapper,
} from './SmallCard.styled';
import useApiUrl from 'hooks/useApiUrl';

const SmallCard: React.FC<OrdinaryCard | ButtonCard> = ({ value, type }) => {
    const wagtailAPI = useApiUrl();
    const hasAction = !!(
        type === ComponentType.ordinaryCard && value.action?.action?.url
    );

    const OrdinaryCard = () => {
        return (
            <CardWrapper>
                <CardBody>
                    <MainContentWrapper $hasAction={hasAction}>
                        <Title>{value.title}</Title>

                        <DescriptionWrapper>
                            <RichText value={value.description} />
                        </DescriptionWrapper>
                    </MainContentWrapper>

                    {hasAction && (
                        <CustomLink href={value.action?.action?.url}>
                            <ActionButton $color={value.action?.accentColor}>
                                {value?.action?.title}
                            </ActionButton>
                        </CustomLink>
                    )}
                </CardBody>
            </CardWrapper>
        );
    };

    if (type === ComponentType.buttonCard) {
        return (
            <CustomLink href={`${wagtailAPI}${value.action.action.url}`}>
                <ButtonCardWrapper>
                    <OrdinaryCard />
                </ButtonCardWrapper>
            </CustomLink>
        );
    }

    if (type === ComponentType.ordinaryCard) {
        return <OrdinaryCard />;
    }

    return null;
};

export default SmallCard;
