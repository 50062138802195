import Carousel from 'components/Carousel';
import { useDisplayType } from 'hooks/useDisplayType';
import { TriangularArrows } from 'types/componentTypes';
import { carouselCustomSettings } from './carouselCustomSettings';
import { CardsWrapper, CarouselWrapper } from './TriangularArrowCard.styled';
import TriangularArrowCard from './TriangularArrowCard/TriangularArrowCard';

const TriangularArrowCards: React.FC<TriangularArrows> = ({ cards }) => {
    const { isMobile } = useDisplayType();
    const settings = carouselCustomSettings(isMobile);

    const triangularCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <TriangularArrowCard
                key={card.id}
                {...card}
                lastItem={i === arrayLength - 1}
            />
        );
    });

    return (
        <>
            {isMobile ? (
                <CarouselWrapper>
                    <Carousel settings={settings}>{triangularCards}</Carousel>
                </CarouselWrapper>
            ) : (
                <CardsWrapper className="cards">{triangularCards}</CardsWrapper>
            )}
        </>
    );
};

export default TriangularArrowCards;
