import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';

interface ActiveTabProps {
    $isActive: boolean;
}

export const ImagedTabWrapper = styled(
    'div',
    transientOptions
)<ActiveTabProps>(({ $isActive, theme }) =>
    mq({
        width: ['255px', '255px', '271px'],
        height: '304px',
        boxShadow: '0px 4px 20px rgba(17, 24, 33, 0.25)',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: $isActive ? `3px solid ${theme.colors.primaryColor}` : 'none',
        cursor: 'pointer',
    })
);

export const LabelWrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    top: '0',
}));

export const Label = styled('div')<ActiveTabProps>(({ theme, $isActive }) => ({
    ...theme.fonts.h7_headline,
    width: '91%',
    borderRadius: '0px 20px 0px 55px',
    height: '42px',
    background: $isActive
        ? theme.colors.primaryColor
        : theme.colors.secondaryColor_4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: $isActive ? theme.colors.white : theme.colors.primaryText,
}));

export const IllustrationWrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const Illustration = styled('img')(() => ({
    maxHeight: '175px',
    maxWidth: '150px',
}));
