import { IconProps } from './types';

const GoToTopIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = 'black',
    style,
    onClick,
}) => {
    return (
        <svg
            onClick={onClick}
            style={style}
            width={width}
            height={height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="19.5"
                stroke={color}
            />
            <path
                d="M26.6991 22.849C26.5017 23.0464 26.234 23.1572 25.9549 23.1572C25.6758 23.1572 25.4081 23.0464 25.2107 22.849L20.0002 17.6385L14.7897 22.849C14.5911 23.0408 14.3252 23.1469 14.0492 23.1445C13.7732 23.1421 13.5092 23.0314 13.3141 22.8362C13.1189 22.641 13.0082 22.377 13.0058 22.101C13.0034 21.825 13.1095 21.5591 13.3012 21.3606L19.256 15.4059C19.4534 15.2085 19.7211 15.0977 20.0002 15.0977C20.2793 15.0977 20.547 15.2085 20.7444 15.4059L26.6991 21.3606C26.8965 21.558 27.0073 21.8257 27.0073 22.1048C27.0073 22.3839 26.8965 22.6516 26.6991 22.849Z"
                fill={color}
            />
        </svg>
    );
};

export default GoToTopIcon;
