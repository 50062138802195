import {
    ComponentRichText,
    ComponentAction,
    ComponentInternalLink,
    ComponentExternalLink,
    ComponentChessBlockSection,
    ParentStyle,
} from 'types/componentTypes';
import {
    Body,
    ContentLeft,
    ContentRight,
    Description,
    Title,
} from './ChessBlockSection.styled';
import RichText from 'components/RichText';
import DecorativeLink from 'components/DecorativeLink';
import Action from 'components/Action';
import useApiUrl from 'hooks/useApiUrl';
import { ComponentStyle } from 'types/constantTypes';
import Image from 'next/image';

const getImageWidth = (parentStyle: ParentStyle) => {
    switch (parentStyle) {
        case ComponentStyle.mediumChessBlock:
            return '450px';
        case ComponentStyle.smallChessBlock:
            return '400px';
        case ComponentStyle.offsetChessBlock:
            return '270px';
        case ComponentStyle.bigChessBlock:
            return '450px';
        case ComponentStyle.customIllustrationChessBlock:
            return '720px';
        default:
            return '200px';
    }
};

const getImageHeight = (parentStyle: ParentStyle) => {
    switch (parentStyle) {
        case ComponentStyle.mediumChessBlock:
            return '360px';
        case ComponentStyle.smallChessBlock:
            return '320px';
        case ComponentStyle.offsetChessBlock:
            return '270px';
        case ComponentStyle.bigChessBlock:
            return '450px';
        case ComponentStyle.customIllustrationChessBlock:
            return '536px';
        default:
            return '200px';
    }
};

const ChessBlockSection: React.FC<ComponentChessBlockSection> = ({
    title,
    image,
    content,
    parentStyle,
}) => {
    const componentsLayout = {
        rich_text: (component: ComponentRichText) => (
            <Description>
                <RichText {...component} />
            </Description>
        ),

        action: (component: ComponentAction) => <Action {...component} />,

        internal_link: (component: ComponentInternalLink) => (
            <DecorativeLink {...component} />
        ),

        external_link: (component: ComponentExternalLink) => (
            <DecorativeLink {...component} />
        ),
    };

    const wagtailAPI = useApiUrl();

    return (
        <Body $parentStyle={parentStyle}>
            <ContentLeft $parentStyle={parentStyle}>
                <Title $parentStyle={parentStyle}>{title}</Title>

                {content.map((component) => {
                    if (!component.type) return null;

                    const type = component.type;
                    const Component = componentsLayout[type] as React.FC;

                    return <Component key={component.id} {...component} />;
                })}
            </ContentLeft>

            <ContentRight className="right-content" $parentStyle={parentStyle}>
                <Image
                    src={wagtailAPI + image.url}
                    width={getImageWidth(parentStyle)}
                    height={getImageHeight(parentStyle)}
                    objectFit="contain"
                />
            </ContentRight>
        </Body>
    );
};

export default ChessBlockSection;
