import { ComponentDividedBlock } from 'types/componentTypes';
import {
    BackgroundMultiple,
    BlockTitle,
    ComponentWrapper,
    Wrapper,
} from './DividedBlock.styled';
import DividedSection from './DividedSection';
import MultipleDividedSection from './MultipleDividedSection';

const DividedBlock: React.FC<ComponentDividedBlock> = ({ value }) => {
    const withTitle = !!value.title;
    const multipleSections = value.sections.length > 1;

    return (
        <Wrapper>
            {withTitle && <BlockTitle>{value.title}</BlockTitle>}

            {multipleSections && (
                <BackgroundMultiple>
                    {value.sections.map((section, i) => {
                        const odd = value.sections.length % 2 !== 0;
                        const lastItem = i === value.sections.length - 1;
                        const oddAndLast = odd && lastItem;

                        return (
                            <MultipleDividedSection
                                {...section}
                                oddAndLast={oddAndLast}
                                key={section.id}
                            />
                        );
                    })}
                </BackgroundMultiple>
            )}

            {!multipleSections && (
                <ComponentWrapper $withTitle={withTitle}>
                    {value.sections.map((section) => (
                        <DividedSection key={section.id} {...section} />
                    ))}
                </ComponentWrapper>
            )}
        </Wrapper>
    );
};

export default DividedBlock;
