import Cards from 'components/Cards';
import RichText from 'components/RichText';
import { useState } from 'react';
import {
    ComponentTabs,
    ComponentRichText,
    TabsContent,
    ComponentCards,
} from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';
import ImagedTab from './ImagedTab';
import RegularTab from './RegularTab';

import {
    Body,
    TitleWrapper,
    Title,
    RegularTabs,
    ImagedTabs,
} from './Tabs.styled';

interface HandlerProps extends TabsContent {
    i: number;
}

const Tabs: React.FC<ComponentTabs> = (props) => {
    const firstTabData = props.value[0]?.value.tabs[0]?.content;
    const [activeTab, setActiveTab] = useState(0);
    const [tabData, setTabData] = useState(firstTabData);

    const activeTabHandler = (params: HandlerProps) => {
        setActiveTab(params.i);
        setTabData(params.content);
    };

    const layout = {
        rich_text: (component: ComponentRichText) => (
            <RichText value={component.value} />
        ),

        cards: (component: ComponentCards) => {
            return <Cards {...component} />;
        },
    };

    return (
        <>
            {props.value.map((tabsType) => {
                return (
                    <Body key={tabsType.id}>
                        <TitleWrapper>
                            <Title>{tabsType.value.title}</Title>
                        </TitleWrapper>

                        {tabsType.type === ComponentType.regularTabs && (
                            <RegularTabs>
                                {tabsType.value.tabs.map((tab, i) => {
                                    return (
                                        <RegularTab
                                            key={i}
                                            isActive={activeTab === i}
                                            title={tab.title}
                                            handler={() =>
                                                activeTabHandler({
                                                    i,
                                                    content: tab.content,
                                                })
                                            }
                                        />
                                    );
                                })}
                            </RegularTabs>
                        )}

                        {tabsType.type === ComponentType.imagedTabs && (
                            <ImagedTabs>
                                {tabsType.value.tabs.map((tab, i) => {
                                    return (
                                        <ImagedTab
                                            key={i}
                                            isActive={activeTab === i}
                                            url={tab.image.url}
                                            title={tab.title}
                                            handler={() =>
                                                activeTabHandler({
                                                    i,
                                                    content: tab.content,
                                                })
                                            }
                                        />
                                    );
                                })}
                            </ImagedTabs>
                        )}

                        {tabData && (
                            <>
                                {tabData.map((content) => {
                                    const componentKey =
                                        content.type as keyof typeof layout;

                                    const Component = layout[
                                        componentKey
                                    ] as React.FC;

                                    if (Component) {
                                        return (
                                            <Component
                                                key={content.id}
                                                {...content}></Component>
                                        );
                                    }

                                    return null;
                                })}
                            </>
                        )}
                    </Body>
                );
            })}
        </>
    );
};

export default Tabs;
