import useApiUrl from 'hooks/useApiUrl';
import { Review } from 'types/componentTypes';
import {
    Card,
    MobileCardWrapper,
    Type,
    BodyContent,
    Title,
    Company,
    PosAndCompany,
    Position,
    Text,
} from './CardFeedbackMobile.styled';
import Image from 'next/image';

interface CardFeedbackProps {
    review: Review;
}

const CardFeedbackMobile: React.FC<CardFeedbackProps> = ({ review }) => {
    const reviewValue = review.value;
    const apiUrl = useApiUrl();
    return (
        <MobileCardWrapper>
            <Type $type={reviewValue.label}>{reviewValue.label}</Type>
            <Card>
                <Image
                    src={apiUrl + reviewValue.photo}
                    width="258px"
                    height="279px"
                    objectFit="cover"
                    style={{ borderRadius: '200px 15px 200px 200px' }}
                />

                <BodyContent>
                    <Title>{reviewValue.fullName}</Title>
                    <PosAndCompany>
                        <Position>{reviewValue.jobTitle}</Position>
                        <Company> | {reviewValue.companyName}</Company>
                    </PosAndCompany>
                    <Text>{reviewValue.description}</Text>
                </BodyContent>
            </Card>
        </MobileCardWrapper>
    );
};

export default CardFeedbackMobile;
