export const customDotsMargin = (marginTop: number, dots: React.FC) => {
    return (
        <div
            style={{
                bottom: `-${marginTop}px`,
            }}>
            {dots}
        </div>
    );
};
