import type { WavyArrowsCards } from 'types/componentTypes';
import { CardsWrapper } from './WavyArrowsCards.styled';
import WavyArrowsCard from './WavyArrowsCard/WavyArrowsCard';

const WavyArrowsCards: React.FC<WavyArrowsCards> = ({ cards }) => {
    const triangularCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <WavyArrowsCard
                key={card.id}
                {...card}
                lastItem={(i + 1) % 3 === 0 || arrayLength === i + 1}
            />
        );
    });

    return <CardsWrapper className="cards">{triangularCards}</CardsWrapper>;
};

export default WavyArrowsCards;
