import FormField, { FixedFormNode } from 'helpers/wagtailFormConstructor';
import { FormProvider, useForm } from 'react-hook-form';
import {
    Form,
    FormContainer,
    FormTitle,
    SendButton,
    AboutWrapper,
} from './FormConstructor.styled';
import RichText from 'components/RichText';
import { useMemo, useState } from 'react';
import {
    createDefaultFormValues,
    createSignUpValidator,
} from 'helpers/createSignUpValidator';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { SERVER_ERROR } from 'constants/toast-messages';
import { camelToSnake } from 'utils/caseconverters';
import sendFormData from 'api/sendFormData';
import Loader from 'components/Loader';

interface FormProps {
    id: string;
    type: string;
    value: {
        meta: {
            slug: string;
            url: string;
        };
        form: { title: string; intro: string; formFields: FixedFormNode[] };
    };
}

export interface PostResponse {
    component_name: string;
    component_props: {
        type: string;
        errors: { [key: string]: string }[];
        form: {
            thanks_text: string;
            err: { [key: string]: string }[];
        };
    };
}

const FormConstructor: React.FC<FormProps> = ({ value: { form, meta } }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const validationSchema = useMemo(
        () => createSignUpValidator(form.formFields),
        [form.formFields]
    );

    const defaultFormValues = useMemo(
        () => createDefaultFormValues(form.formFields),
        [form.formFields]
    );

    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: zodResolver(validationSchema),
    });

    const { handleSubmit } = methods;

    const submitHandler = async () => {
        setIsLoading(true);
        const formValues = methods.getValues();

        const formData = new FormData();

        for (const [key, value] of Object.entries(formValues)) {
            if (Array.isArray(value)) {
                value.forEach((item) => formData.append(key, item));
            } else {
                formData.append(key, value);
            }
        }

        try {
            const { data } = await sendFormData<PostResponse>({
                url: meta.url,
                formData,
                setOnProgress: setProgress,
            });

            const form = data.component_props.form;

            if (form.thanks_text) {
                methods.reset();
                setIsLoading(false);
                setProgress(0);
                return toast.success(form.thanks_text);
            }

            for (const [key, value] of Object.entries(form.err)) {
                const valueAny = value;
                methods.setError(camelToSnake(key), {
                    message: valueAny.toString(),
                });
            }

            return;
        } catch (e) {
            setProgress(0);
            setIsLoading(false);
            return toast.error(SERVER_ERROR);
        }
    };

    return (
        <FormProvider {...methods}>
            <FormContainer>
                <FormTitle>{form.title}</FormTitle>

                <AboutWrapper>
                    <RichText value={form.intro} />
                </AboutWrapper>

                <Form onSubmit={handleSubmit(submitHandler)}>
                    {form.formFields.map((el, idx) => (
                        <FormField
                            key={idx}
                            fieldNode={el}
                            formErrors={methods.formState.errors}
                            progress={progress}
                        />
                    ))}

                    <SendButton type="submit" disabled={isLoading}>
                        {isLoading ? (
                            <Loader height="100%" size={25} />
                        ) : (
                            'Send message'
                        )}
                    </SendButton>
                </Form>
            </FormContainer>
        </FormProvider>
    );
};

export default FormConstructor;
