import { mq } from 'theme/mediaQueries';
import { pxToRem } from 'helpers/pxToRem';
import RichText from 'components/RichText';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import { transientOptions } from 'helpers/transientOptions';

export const MainContainer = styled('div')(() => ({
    marginTop: '90px',
    width: '100%',
}));

export const MainTitle = styled('h2')(({ theme }) => ({
    ...theme.fonts.h3_headline,
    color: 'black',
    textAlign: 'center',
}));

export const Description = styled('div')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.secondaryText,
    textAlign: 'center',
    margin: '0 5px',
}));

export const AccordionContainer = styled('div')(() =>
    mq({
        margin: '60px auto 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        width: ['90%', '90%', '935px'],
    })
);

export const StyledAccordion = styled(
    Accordion,
    transientOptions
)(() => ({
    color: 'black',
    border: 'none',
    boxShadow: 'none',

    '&:before': {
        display: 'none',
    },
}));

export const AccordionTitle = styled('div')(() => ({
    display: 'flex',
    gap: '14px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '12x',
}));

export const AccordionBody = styled('div')({
    margin: '0',
});

export const StyledRichDescription = styled(RichText)(({ theme }) =>
    mq({
        fontSize: pxToRem(16),

        '& p': {
            ...theme.fonts.body_2,
            marginTop: '0',
        },
    })
);

export const StyledRichTitle = styled(RichText)(({ theme }) =>
    mq({
        fontSize: pxToRem(16),

        '& p': {
            ...theme.fonts.h6_headline,
            marginTop: '0',
        },
    })
);
