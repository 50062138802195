import {
    CardWrapper,
    ButtonWrapper,
    DescriptionRichText,
    FeaturesRichText,
    PercentBackground,
    PrimaryText,
    SecondaryText,
    Title,
    Button,
} from './PlansCard.styled';
import type { PlansCard } from 'types/componentTypes';
import RichText from 'components/RichText';
import CustomLink from 'components/CustomLink';

const PlansCard: React.FC<PlansCard> = ({
    title,
    description,
    primaryText,
    secondaryText,
    features,
    action,
}) => {
    return (
        <CardWrapper>
            <div>
                <Title>{title}</Title>

                <DescriptionRichText>
                    <RichText value={description} />
                </DescriptionRichText>

                <PercentBackground>
                    <PrimaryText>{primaryText}</PrimaryText>

                    {secondaryText && (
                        <SecondaryText>{secondaryText}</SecondaryText>
                    )}
                </PercentBackground>

                <FeaturesRichText>
                    <RichText value={features} />
                </FeaturesRichText>
            </div>

            <ButtonWrapper>
                <CustomLink href={action.action.url}>
                    <Button $buttonColor={action.accentColor}>
                        {action.title}
                    </Button>
                </CustomLink>
            </ButtonWrapper>
        </CardWrapper>
    );
};

export default PlansCard;
