import { Wrapper } from './Plans.styled';
import { ComponentsPlans } from 'types/componentTypes';
import PlansCard from './PlansCard';

const Plans: React.FC<ComponentsPlans> = (props) => {
    return (
        <Wrapper>
            {props.value.values.map((card, i) => {
                return <PlansCard key={i} {...card} />;
            })}
        </Wrapper>
    );
};

export default Plans;
