import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';

export const Background = styled('div')(({ theme }) =>
    mq({
        width: '100%',
        height: '466px',
        marginTop: ['120px', '120px', '133px'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.colors.secondaryColor_3,
        borderRadius: ['204px 0px 167px', '204px 0px 167px', '300px 0px 200px'],
        zIndex: '1',
        position: 'relative',
    })
);

export const CarouselBlock = styled('div')(() =>
    mq({
        width: ['100%', '86%', '80%'],
        display: 'flex',
        justifyContent: 'space-between',

        [mobileStyles]: {
            justifyContent: 'center',
        },
    })
);

export const MobileWrapper = styled('div')(() => ({
    marginTop: '70px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
