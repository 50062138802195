import { mq } from './../../theme/mediaQueries';
import { styled } from '@mui/material';

export const Title = styled('div')(({ theme }) => ({
    ...theme.fonts.body_5,
    color: theme.colors.secondaryColor_2,
}));

export const Blank = styled('div')(({ theme }) =>
    mq({
        width: '86%',
        maxWidth: theme.breakpoints.values.desktop,
        marginTop: ['50px', '80px', '80px'],
        display: 'flex',
        justifyContent: 'center',
        gap: '32px',
        flexDirection: 'column',
    })
);

export const BlankTitle = styled('div')(({ theme }) => ({
    ...theme.fonts.h4_headline,
    color: theme.colors.primaryText,
    textAlign: 'center',
}));

