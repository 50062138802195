import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const SliderWrapper = styled('div')(() => ({
    width: '89%',

    '@media (max-width: 1300px)': {
        width: '85%',
    },

    '@media (max-width: 950px)': {
        width: '80%',
    },

    '@media (max-width: 500px)': {
        width: '100%',
    },
}));

const alignArrows = {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '45px',
};

export const PrevWrapper = styled('div')(() =>
    mq({
        ...alignArrows,
        marginRight: '27px',
        flexDirection: 'row',
        display: ['none', 'flex', 'flex'],
    })
);

export const NextWrapper = styled('div')(() =>
    mq({
        ...alignArrows,
        marginLeft: '27px',
        flexDirection: 'row',
        display: ['none', 'flex', 'flex'],
    })
);

const arrowStyles = { width: '40px', height: '19px', cursor: 'pointer' };

export const Prev = styled('img')(() => ({
    ...arrowStyles,
}));

export const Next = styled('img')(() => ({
    ...arrowStyles,
}));
