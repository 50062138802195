import { styled } from '@mui/material/styles';
import { mq } from 'theme/mediaQueries';
import { tabletStyles } from 'types/displayTypeStyles';

export const Card = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: ['305px', '236px', '264px'],
        margin: '0px 5px 7px 5px',
        height: ['370px', '286px', '330px'],
        borderRadius: '100px 4px 0px 0px',
        overflow: 'hidden',
    })
);

export const ImageWrapper = styled('div')(() =>
    mq({
        height: ['257px', '197px', '220px'],
        position: 'relative',
    })
);

export const CardBody = styled('div')(({ theme }) =>
    mq({
        height: ['115px', '90px', '100px'],
        width: '100%',
        background: theme.colors.primaryColor,
        marginTop: '-1px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '0px 0px 4px 4px',
        padding: '18px 20px',
        overflow: 'hidden',

        [tabletStyles]: {
            padding: '10px 20px',
        },

        '&:hover': {
            background: theme.colors.white,

            '& p': {
                color: theme.colors.primaryText,
            },

            '& h6': {
                color: theme.colors.primaryText,
            },
        },
    })
);

export const Name = styled('h6')(({ theme }) => ({
    ...theme.fonts.h6_headline,
    color: theme.colors.white,
    overflow: 'hidden',
}));

export const JobTitle = styled('p')(({ theme }) => ({
    ...theme.fonts.body_2,
    marginTop: '5px',
    color: theme.colors.white,
    overflow: 'hidden',
}));
