import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';
import { pxToRem } from '@helpers/pxToRem';

interface ContentProps {
    $withReferences: boolean;
}

export const Wrapper = styled('div')<ContentProps>(({ theme }) =>
    mq({
        width: '95%',
        height: '100%',
        borderRadius: ['10px', '10px 10px 134px 10px', '10px 10px 172px 10px'],
        padding: '15px 15px 15px 50px',
        display: 'flex',
        gap: '24px',
        justifyContent: 'space-between',
        color: theme.colors.primaryColor,
        alignItems: 'center',
        marginTop: '40px',

        [mobileStyles]: {
            padding: '34px 32px',
            width: '91%',
        },
    })
);

export const ContentL = styled(
    'div',
    transientOptions
)<ContentWrapperProps>(({ $withReferences }) =>
    mq({
        display: 'flex',
        flex: '42% 1 1',
        justifyContent: $withReferences ? 'space-between' : 'center',
        flexDirection: 'column',

        height: ['100%', '77%', '71%'],

        [mobileStyles]: {
            width: '100%',
        },
    })
);

interface ContentWrapperProps {
    $withReferences: boolean;
}

export const Title = styled('h2')(({ theme }) => ({
    ...theme.fonts.h1_headline,
    color: theme.colors.primaryColor,

    [tabletStyles]: {
        ...theme.fonts.h2_headline,
    },

    [mobileStyles]: {
        ...theme.fonts.h3_headline,
    },
}));

export const Description = styled('div')(({ theme }) =>
    mq({
        maxWidth: '415px',
        letterSpacing: '1px',
        marginTop: ['25px', '40px', '40px'],
        color: theme.colors.secondaryText,

        '& p': {
            ...theme.fonts.body_5,
            fontWeight: '500 !important',
            fontSize: `${pxToRem(22)} !important`,
            lineHeight: `${pxToRem(32)} !important`,
            letterSpacing: '1px',
            marginTop: '0px!important',
        },
    })
);

export const ContentR = styled('div')(() => ({
    flex: '58% 1 1',
    height: '100%',

    [mobileStyles]: {
        display: 'none',
    },
}));

export const Img = styled('img')(() =>
    mq({
        width: '100%',
        height: '100%',
    })
);

export const Buttons = styled('div')(({ theme }) =>
    mq({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '20px',
        marginTop: ['25px', '60px'],

        [mobileStyles]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '9px',
        },

        '& .decorativeLink': {
            width: 'auto!important',

            '& p, img': {
                filter: 'brightness(0) invert(1)',
            },
        },

        '& .action': {
            width: 'auto!important',
            marginTop: '0px!important',

            '& button': {
                ...theme.fonts.button_text,
                padding: '16px 24px',
            },
        },
    })
);
