import {
    Next,
    Prev,
    SliderWrapper,
    NextWrapper,
    PrevWrapper,
} from './Carousel.styled';
import { useRef } from 'react';

import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';

interface SettingsProps {
    settings: Settings;
}

const Carousel: React.FC<SettingsProps> = ({ children, settings }) => {
    const customSlider = useRef<Slider | null>(null);

    const gotoNext = () => {
        customSlider.current?.slickNext();
    };

    const gotoPrev = () => {
        customSlider.current?.slickPrev();
    };
    return (
        <>
            <PrevWrapper>
                <Prev src="/img/prev.svg" onClick={() => gotoPrev()} />
            </PrevWrapper>

            <SliderWrapper>
                <Slider {...settings} ref={customSlider}>
                    {children}
                </Slider>
            </SliderWrapper>

            <NextWrapper>
                <Next src="/img/next.svg" onClick={() => gotoNext()} />
            </NextWrapper>
        </>
    );
};

export default Carousel;
