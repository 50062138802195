import Drop from './WrappedImage/Drop';
import GrayScale from './StyledSection/GrayScale';
import Infographics from './WrappedImage/Infographics';
import StyledSection from './StyledSection';
import DecorativeLink from './DecorativeLink';
import Plans from './Plans';
import Tabs from './Tabs';
import Cards from './Cards';
import RichText from './RichText';
import WrappedImage from './WrappedImage';
import Images from './Images';
import ImageComponent from './ImageComponent';
import Action from './Action';
import CharBlock from './CharBlock';
import Hero from './Banner';
import DividedBlock from './DividedBlock';
import PartnerList from './Home/PartnerList';
import ReviewSlider from './Home/ReviewSlider';
import ActionsList from './ActionsList';
import CustomAccordion from './CustomAccordion/';
import FormConstructor from './FormConstructor';

export default {
    grayscale: GrayScale,
    drop_block: Drop,
    infographics_block: Infographics,
    internal_link: DecorativeLink,
    external_link: DecorativeLink,
    styled_section: StyledSection,
    plans: Plans,
    tabs: Tabs,
    cards: Cards,
    rich_text: RichText,
    wrapped_image: WrappedImage,
    images: Images,
    image: ImageComponent,
    action: Action,
    char_block: CharBlock,
    hero_block: Hero,
    divided_sections_block: DividedBlock,
    partner_list: PartnerList,
    review_slider: ReviewSlider,
    actions_list: ActionsList,
    accordion: CustomAccordion,
    form: FormConstructor,
};
