import useApiUrl from 'hooks/useApiUrl';
import { Partner } from 'types/componentTypes';
import {
    Card,
    CardBody,
    Name,
    JobTitle,
    ImageWrapper,
} from './CarouselCard.styled';
import Image from 'next/image';

interface CarouselProps {
    partner: Partner;
}

const ProfessionalsCard = ({ partner }: CarouselProps) => {
    const apiUrl = useApiUrl();

    const partnerValue = partner.value;
    const fullName = `${partnerValue.firstName} ${partnerValue.lastName}`;

    return (
        <Card>
            <ImageWrapper>
                <Image
                    src={`${apiUrl}${partnerValue.photo.path}`}
                    alt="team"
                    layout="fill"
                    objectFit="cover"
                    width="100%"
                    height="100%"
                />
            </ImageWrapper>

            <CardBody>
                <Name>{fullName}</Name>
                <JobTitle>{partnerValue.jobTitle}</JobTitle>
            </CardBody>
        </Card>
    );
};

export default ProfessionalsCard;
