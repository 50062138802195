import Drop from 'components/WrappedImage/Drop';
import { ComponentWrappedImage } from 'types/componentTypes';
import { ComponentStyle, ComponentType } from 'types/constantTypes';
import ChessBlockSection from './ChessBlockSection/ChessBlockSection';
import CroppedPhotoBlock from './CroppedPhotoBlock';
import Infographics from './Infographics';

const WrappedImage: React.FC<ComponentWrappedImage> = ({
    value,
    parentStyle,
}) => {
    switch (value.style) {
        case ComponentType.drop:
            return <Drop {...value} />;

        case ComponentType.infographics:
            return <Infographics {...value} />;

        case ComponentType.croppedPhotoBlock:
            return <CroppedPhotoBlock {...value} />;

        case ComponentType.none:
            return (
                <ChessBlockSection
                    {...value}
                    parentStyle={parentStyle || ComponentStyle.mediumChessBlock}
                />
            );

        default:
            return null;
    }
};

export default WrappedImage;
