import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const CardsWrapper = styled('div')(() =>
    mq({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: ['35px auto 0', '90px auto 0', '90px auto 0'],
    })
);

export const CarouselWrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '25px',
    marginTop: '37px',
}));
