import { CardsWrapper } from './MediumCards.styled';
import MediumCard from './MediumCard/MediumCard';
import type { MediumCards } from 'types/componentTypes';

const MediumCards: React.FC<MediumCards> = ({ cards }) => {
    const MediumCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <MediumCard
                key={card.id}
                {...card}
                lastItem={i === arrayLength - 1}
            />
        );
    });

    return <CardsWrapper className="cards">{MediumCards}</CardsWrapper>;
};

export default MediumCards;
