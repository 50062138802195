import { mobileStyles } from 'types/displayTypeStyles';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const Wrapper = styled('div')(() =>
    mq({
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: '23px',
        marginTop: ['60px', '70px', '110px'],

        [mobileStyles]: {
            display: 'flex',
            flexDirection: 'column',
        },
    })
);
