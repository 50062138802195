import {
    Background,
    Description,
    Title,
    Button,
} from './DividedSection.styled';
import type {
    ComponentAction,
    ComponentRichText,
    DividedSection,
} from 'types/componentTypes';
import RichText from 'components/RichText';
import CustomLink from 'components/CustomLink';
import useApiUrl from 'hooks/useApiUrl';

const DividedSection: React.FC<DividedSection> = ({ value }) => {
    const apiURL = useApiUrl();

    const layout = {
        rich_text: (component: ComponentRichText) => {
            return (
                <Description>
                    <RichText {...component} />
                </Description>
            );
        },

        action: (component: ComponentAction) => {
            return (
                <CustomLink href={`${apiURL}${component.value.action.url}`}>
                    <Button>{component.value.title}</Button>
                </CustomLink>
            );
        },
    };

    return (
        <Background>
            <Title>{value.title}</Title>

            {value.content.map((component) => {
                if (component.type) {
                    const Component = layout[component.type] as React.FC;
                    return (
                        <Component
                            key={component.id}
                            {...component}></Component>
                    );
                }

                return null;
            })}
        </Background>
    );
};

export default DividedSection;
