import CustomLink from 'components/CustomLink';
import RichText from 'components/RichText';
import {
    ComponentAction,
    ComponentRichText,
    DividedSection,
} from 'types/componentTypes';
import {
    MultipleSection,
    Description,
    Button,
    Title,
} from './MultipleDividedSection.styled';

const MultipleDividedSection: React.FC<DividedSection> = ({
    value,
    oddAndLast,
}) => {
    const layout = {
        rich_text: (component: ComponentRichText) => {
            return (
                <Description>
                    <RichText {...component} />
                </Description>
            );
        },

        action: (component: ComponentAction) => {
            return (
                <CustomLink
                    href={component.value.action.url}
                    fullWidth
                    style={{ marginTop: 'auto' }}>
                    <Button>{component.value.title}</Button>
                </CustomLink>
            );
        },
    };

    return (
        <MultipleSection className="multipleSection" $oddAndLast={oddAndLast}>
            <Title>{value.title}</Title>

            {value.content.map((component) => {
                if (component.type) {
                    const Component = layout[component.type] as React.FC;

                    return <Component key={component.id} {...component} />;
                }

                return null;
            })}
        </MultipleSection>
    );
};

export default MultipleDividedSection;
