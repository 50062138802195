import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';
import { tabletStyles } from 'types/displayTypeStyles';

export const CardWrapper = styled('div')(() => ({
    width: '100%',
    maxWidth: '940px',
    margin: '22px 12px 12px 22px',
    height: '248px',
    position: 'relative',
    zIndex: '100',
}));

export const Slide = styled('div')(({ theme }) => ({
    width: '100%',
    height: '248px',
    background: theme.colors.white,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '200px 70px 50px 200px',
    zIndex: '200',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
}));

interface TypeProps {
    $type: string;
}

export const Circle = styled(
    'div',
    transientOptions
)<TypeProps>(({ theme, $type }) => ({
    position: 'absolute',
    width: '50%',
    maxWidth: '264px',
    height: '100%',
    background:
        $type === 'partner'
            ? theme.colors.secondaryColor_basic
            : theme.colors.primaryColor,
    borderRadius: '230.5px 20px 227px 240px',
    top: '-15px',
    left: '-10px',
    zIndex: '100',
}));

export const Type = styled(
    'div',
    transientOptions
)<TypeProps>(({ theme, $type }) => ({
    position: 'absolute',
    width: '18.5%',
    height: '44px',
    background:
        $type === 'partner'
            ? theme.colors.secondaryColor_basic
            : theme.colors.primaryColor,
    borderRadius: '0px 200px 0px 200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '81.5%',
    zIndex: '300',
}));

export const TypeText = styled(
    'p',
    transientOptions
)<TypeProps>(({ theme, $type }) => ({
    ...theme.fonts.h7_headline,
    color: $type === 'partner' ? theme.colors.primaryText : theme.colors.white,

    '&:first-letter': {
        textTransform: 'uppercase',
    },
}));

export const SlideContent = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    width: '100%',
    gap: '35px',
}));

export const BodyContent = styled('div')(() => ({
    flexDirection: 'column',
}));

export const PosAndCompany = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '15px',
}));

export const Title = styled('h4')(({ theme }) => ({
    ...theme.fonts.h4_headline,
    color: theme.colors.primaryText,
}));

export const Position = styled('p')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.primaryText,
}));

export const Company = styled('p')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.secondaryText,
    whiteSpace: 'pre-wrap',
}));

export const Text = styled('p')(({ theme }) => {
    return mq({
        ...theme.fonts.body_2,
        color: theme.colors.secondaryText,
        marginTop: '14px',
        marginRight: ['31px', '31px', '81px'],

        [tabletStyles]: {
            ...theme.fonts.body_4,
            marginRight: '31px',
        },
    });
});

export const ImgWrapper = styled('div')({
    display: 'flex',
    flexGrow: '1',
    flexShrink: 0,
});
