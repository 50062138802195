import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';
import { MainContentWrapperShared } from 'components/Cards/Cards.shared.styled';
import { ComponentType } from 'types/constantTypes';
interface CardWrapperProps {
    $type: ComponentType;
}

export const CardWrapper = styled(
    'div',
    transientOptions
)<CardWrapperProps>(({ $type }) =>
    mq({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: ['90%', 'auto'],
        cursor: $type === ComponentType.buttonCard && 'pointer',

        [mobileStyles]: {
            margin: '0 16px',
        },
    })
);

export const ButtonCardWrapper = styled('div')(() => ({
    cursor: 'pointer',
}));

export const CardBody = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: ['row', 'column'],
        alignItems: 'center',
        gap: '20px',
        padding: ['50px 30px 35px', '50px 15px 35px'],
        width: ['100%', '235px', '275px'],
        height: ['190px', '315px', '330px'],
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px 20px 4px 4px',
    })
);

export const MainContentWrapper = styled(MainContentWrapperShared)(
    ({ $hasAction }) =>
        mq({
            height: $hasAction ? ['100%', '170px', '180px'] : '100%',
            width: $hasAction ? ['50%', 'auto'] : ['100%', 'auto'],
            display: 'flex',
            gap: '32px',
            flexDirection: ['row', 'column'],
        })
);

export const ImgWrapper = styled('div')(() =>
    mq({
        height: ['100%', '50%'],
        width: ['100%', '100%'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })
);

export const Img = styled('img')(() => ({
    maxHeight: '100%',
    maxWidth: '100%',

    [mobileStyles]: {
        maxWidth: '170px',
        maxHeight: '165px',
    },
}));

export const Title = styled('h6')(({ theme }) => {
    return mq({
        ...theme.fonts.h6_headline,
        color: theme.colors.primaryText,
        textAlign: ['left', 'center'],
        margin: '20px 0',

        [mobileStyles]: {
            ...theme.fonts.h7_headline,
        },
    });
});
