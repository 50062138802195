import BubblePhoto from 'components/BubblePhoto';
import {
    ComponentDrop,
    ComponentRichText,
    ComponentAction,
    ComponentInternalLink,
    ComponentExternalLink,
} from 'types/componentTypes';
import {
    Background,
    Body,
    ContentLeft,
    ContentRight,
    Title,
    Description,
} from './Drop.styled';
import RichText from 'components/RichText';
import DecorativeLink from 'components/DecorativeLink';
import Action from 'components/Action';

const Drop: React.FC<ComponentDrop> = ({ title, image, content }) => {
    const componentsLayout = {
        rich_text: (component: ComponentRichText) => (
            <Description>
                <RichText {...component} />
            </Description>
        ),

        action: (component: ComponentAction) => <Action {...component} />,

        internal_link: (component: ComponentInternalLink) => (
            <DecorativeLink {...component} />
        ),

        external_link: (component: ComponentExternalLink) => (
            <DecorativeLink {...component} />
        ),
    };

    return (
        <Background>
            <Body>
                <ContentLeft>
                    <Title>{title}</Title>

                    {content.map((component) => {
                        if (component.type) {
                            const Component = componentsLayout[
                                component.type
                            ] as React.FC;

                            return (
                                <Component
                                    key={component.id}
                                    {...component}></Component>
                            );
                        }

                        return null;
                    })}
                </ContentLeft>

                <ContentRight>
                    <BubblePhoto
                        imgUrl={image.url}
                        $circleColor={image.accentColor}
                    />
                </ContentRight>
            </Body>
        </Background>
    );
};

export default Drop;
