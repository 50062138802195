import { styled } from '@mui/material/styles';
import { mq } from 'theme/mediaQueries';

export const Content = styled('div')(({ theme }) =>
    mq({
        width: '90%',
        maxWidth: theme.breakpoints.values.desktop,
        filter: 'grayscale(100%) brightness(60%) sepia(100%) hue-rotate(180deg) opacity(0.6)',
        marginTop: ['50px', '88px', '118px'],
        display: 'flex',
        justifyContent: 'center',
        gap: '32px',
        flexDirection: 'column',

        img: {
            maxWidth: '165px',
            maxHeight: '40px',
        },
    })
);
