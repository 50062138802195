import Image from 'next/image';
import {
    ColoredCircle,
    ImageWrapper,
    ImgContainer,
    WhiteCircle,
} from './BubblePhoto.styled';
import useApiUrl from 'hooks/useApiUrl';

interface BubblePhotoProps {
    imgUrl: string;
    $circleColor?: string;
}

const BubblePhoto: React.FC<BubblePhotoProps> = ({ imgUrl, $circleColor }) => {
    const apiUrl = useApiUrl();

    return (
        <ImgContainer>
            <ColoredCircle $circleColor={$circleColor} />

            <WhiteCircle>
                <ImageWrapper>
                    <Image
                        src={`${apiUrl}${imgUrl}`}
                        layout="fill"
                        objectFit="contain"
                    />
                </ImageWrapper>
            </WhiteCircle>
        </ImgContainer>
    );
};

export default BubblePhoto;
