import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';
import {
    DescriptionWrapperShared,
    MainContentWrapperShared,
} from 'components/Cards/Cards.shared.styled';

export const CardWrapper = styled(
    'div',
    transientOptions
)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [mobileStyles]: {
        margin: '5px 5px 13px 5px',
    },
}));

export const ButtonCardWrapper = styled('div')(() => ({
    cursor: 'pointer',
}));

export const CardBody = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: '35px 16px 40px',
        width: ['250px', '270px', '270px'],
        height: ['300px', '314px', '314px'],
        boxShadow: '0px 4px 20px rgba(17, 24, 33, 0.25)',
        borderRadius: '20px 20px 4px 4px',
    })
);

export const MainContentWrapper = styled(MainContentWrapperShared)(() => ({}));

export const Title = styled('h5')(({ theme }) => {
    return mq({
        ...theme.fonts.h4_headline,
        color: theme.colors.primaryText,
        textAlign: 'center',
        margin: '20px 0',

        [mobileStyles]: {
            ...theme.fonts.h5_headline,
        },
    });
});

export const DescriptionWrapper = styled(DescriptionWrapperShared)(
    ({ theme }) =>
        mq({
            maxWidth: ['100%', '100%', '100%'],
            textAlign: 'center',
            overflow: 'hidden',

            '& p': {
                ...theme.fonts.body_6,
            },
        })
);
