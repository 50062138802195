import useApiUrl from 'hooks/useApiUrl';
import { ComponentImage } from 'types/componentTypes';
import { Img, Wrapper } from './ImageComponent.styled';

const ImageComponent: React.FC<ComponentImage> = ({ value }) => {
    const apiUrl = useApiUrl();

    return (
        <Wrapper>
            <Img src={`${apiUrl}${value.url}`} />
        </Wrapper>
    );
};

export default ImageComponent;
