import { CardsWrapper } from './CustomIllustrationCards.styled';
import CustomIllustrationCard from './CustomIllustrationCard';
import type { CustomIllustrationCards } from 'types/componentTypes';

const CustomIllustrationCards: React.FC<CustomIllustrationCards> = ({ cards }) => {
    const customIllustrationCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <CustomIllustrationCard
                key={card.id}
                cardNumber={i + 1}
                lastItem={i === arrayLength - 1}
                {...card}
            />
        );
    });

    return <CardsWrapper className="cards">{customIllustrationCards}</CardsWrapper>;
};

export default CustomIllustrationCards;
