import React from 'react';
import { StyledSection } from 'types/componentTypes';
import { Blank } from '../StyledSection.styled';
import { ComponentStyle } from 'types/constantTypes';
import { ChessContainer, ChessTitle } from './ChessBlock.styled';
import componentsMap from 'components/componentsMap';

const ChessBlock: React.FC<Partial<Omit<StyledSection, 'id'>>> = ({
    value,
}) => {
    if (!value) {
        return null;
    }

    return (
        <Blank>
            <ChessTitle>{value.title}</ChessTitle>

            <ChessContainer
                $sectionStyle={value.style}
                $offset={value.style === ComponentStyle.offsetChessBlock}>
                {value.content.map((component) => {
                    const componentKey =
                        component.type as keyof typeof componentsMap;

                    const Component = componentsMap[componentKey] as React.FC;

                    if (!Component) return null;

                    component.parentStyle = value.style;
                    return <Component key={component.id} {...component} />;
                })}
            </ChessContainer>
        </Blank>
    );
};

export default ChessBlock;
