import { mq } from './../../../theme/mediaQueries';
import { mobileStyles } from './../../../types/displayTypeStyles';
import { styled } from '@mui/material';

export const Background = styled('div')(({ theme }) =>
    mq({
        width: '95%',
        minHeight: '258px',
        backgroundColor: theme.colors.primaryColor,
        borderRadius: '100px 10px',
        padding: ['48px 20px', '48px 20px', '66px 32px'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        [mobileStyles]: {
            borderRadius: '50px 10px;',
        },
    })
);

export const Title = styled('div')(({ theme }) => ({
    ...theme.fonts.h3_headline,
    color: theme.colors.white,
}));

export const Description = styled('div')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.white,
    marginTop: '10px',

    [mobileStyles]: {
        marginTop: '25px',
    },

    '& p': {
        marginTop: '0px!important',
    },
}));

export const Button = styled('button')(({ theme }) => ({
    padding: '12px 47px',
    ...theme.fonts.button_text,
    width: 'fit-content',
    background: theme.colors.white,
    borderRadius: '50px',
    color: theme.colors.primaryText,
    border: 'none',
    cursor: 'pointer',
    marginTop: '40px',

    [mobileStyles]: {
        marginTop: '45px',
    },
}));
