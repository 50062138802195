import { styled } from '@mui/material';
import { mobileStyles } from 'types/displayTypeStyles';

export const ButtonsWrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '56px',
    gap: '9px',

    '& .action': {
        width: 'auto',
        marginTop: '0px',
    },

    [mobileStyles]: {
        flexDirection: 'column',
    },
}));
