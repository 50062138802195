import RichText from 'components/RichText';
import { ComponentHeroValues } from 'types/componentTypes';
import {
    ContentBlock,
    Details,
    Title,
    Description,
    Background,
    Buttons,
    Img,
} from './Legal.styled';
import useApiUrl from 'hooks/useApiUrl';
import referenceComponentsMap from '../referenceComponentsMap';

const LegalBanner: React.FC<ComponentHeroValues> = ({
    title,
    description,
    images,
    references,
}) => {
    const apiUrl = useApiUrl();
    const withReferences = !!references.length;

    const buttonsBlock = references.map((component) => {
        const componentKey = component.type as keyof typeof referenceComponentsMap;

        const Component = referenceComponentsMap[componentKey] as React.FC;

        if (!Component) return null;

        return <Component key={component.id} {...component} />;
    });

    return (
        <Background>
            <ContentBlock>
                <Details>
                    <Title data-testid="legal">{title}</Title>

                    <Description>
                        <RichText value={description} />
                    </Description>

                    {withReferences && <Buttons>{buttonsBlock}</Buttons>}
                </Details>

                {images.map((image, i) => {
                    return (
                        <Img
                            key={i}
                            src={`${apiUrl}${image.url}`}
                            alt={image.title}
                        />
                    );
                })}
            </ContentBlock>
        </Background>
    );
};

export default LegalBanner;
