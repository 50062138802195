import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';
import { pxToRem } from '@helpers/pxToRem';

interface ContentProps {
    $withReferences: boolean;
}

export const Wrapper = styled('div')<ContentProps>(() =>
    mq({
        width: '95%',
        height: '100%',
        borderRadius: ['10px', '10px 10px 134px 10px', '10px 10px 172px 10px'],
        padding: '15px 15px 15px 50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        alignItems: 'center',
        marginTop: '40px',

        [mobileStyles]: {
            padding: '34px 32px',
            width: '91%',
        },
    })
);

export const Title = styled('h2')(({ theme }) => ({
    ...theme.fonts.h2_headline,
    color: theme.colors.primaryColor,

    [tabletStyles]: {
        ...theme.fonts.h3_headline,
    },

    [mobileStyles]: {
        ...theme.fonts.h4_headline,
    },
}));

export const Description = styled('div')(({ theme }) =>
    mq({
        maxWidth: '415px',
        marginTop: ['25px', '40px', '40px'],
        color: theme.colors.primaryColor,

        '& p': {
            ...theme.fonts.h5_headline,
            fontWeight: '600 !important',
            fontSize: `${pxToRem(26)} !important`,
            lineHeight: `${pxToRem(32)} !important`,
            letterSpacing: '0.5px',
            marginTop: '0px!important',
        },
    })
);

export const Img = styled('img')(() =>
    mq({
        borderRadius: [
            '10px 10px 130px 10px',
            '10px 10px 130px 10px',
            '10px 10px 170px 10px',
        ],
        objectFit: 'cover',
        width: '100%',
        height: '100%',
    })
);

export const Images = styled('div')(() =>
    mq({
        marginTop: ['25px', '60px'],
        width: '100%',
        position: 'relative',
    })
);

export const Buttons = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '20px',
    marginTop: '24px',

    [mobileStyles]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '9px',
    },

    '& .decorativeLink': {
        width: 'auto!important',

        '& p, img': {
            filter: 'brightness(0) invert(1)',
        },
    },

    '& .action': {
        width: 'auto!important',
        marginTop: '0px!important',
    },
}));
