import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

export const Content = styled('div')(() =>
    mq({
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: ['40px', '63px', '63px'],
    })
);

export const ContentL = styled('div')(() =>
    mq({ width: ['100%', '45%', '50%'] })
);

export const Buttons = styled('div')(() =>
    mq({
        display: 'flex',
        alignItems: 'center',
        marginTop: ['32px', '32px', '54px'],
        gap: ['20px', '20px', '35px'],

        '& .decorativeLink': {
            width: 'auto!important',
        },

        '& .action': {
            width: 'auto!important',
            marginTop: '0px!important',
        },
    })
);

export const Title = styled('div')(({ theme }) => {
    return mq({
        ...theme.fonts.h1_headline,
        color: theme.colors.primaryColor,

        [tabletStyles]: {
            ...theme.fonts.h3_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.h4_headline,
        },
    });
});

export const Description = styled('div')(({ theme }) => {
    return mq({
        ...theme.fonts.body_5,
        color: theme.colors.secondaryText,
        marginTop: '34px',

        '& p': {
            ...theme.fonts.body_5,

            [tabletStyles]: {
                ...theme.fonts.body_2,
            },

            [mobileStyles]: {
                ...theme.fonts.body_1,
            },
        },
    });
});

export const ContentR = styled('div')(() =>
    mq({
        position: 'relative',
        width: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',

        [mobileStyles]: {
            display: 'none',
        },
    })
);

export const ImgL = styled('div')(() =>
    mq({
        borderRadius: '200px 15px 200px 200px',
        objectFit: 'cover',
        width: '53%',
        height: '90%',
        maxHeight: '375px',
        minHeight: '275px',
        minWidth: '260px',
        overflow: 'hidden',
        position: 'relative',
    })
);

export const ImgR = styled('div')(() =>
    mq({
        borderRadius: ' 200px 200px 150px 15px',
        objectFit: 'cover',
        width: '39%',
        height: '100%',
        maxHeight: '420px',
        minHeight: '307px',
        minWidth: '174px',
        position: 'relative',
        overflow: 'hidden',
    })
);

export const Icon = styled('div')(() =>
    mq({
        position: 'absolute',
        width: '30%',
        minWidth: '164px',
        top: '60%',
        left: '40%',
        bottom: '0',
    })
);
