import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const CarouselWrapper = styled('div')(() =>
    mq({
        width: '90%',
        marginTop: ['77px', '90px', '150px'],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: ['center', 'space-between', 'space-between'],
    })
);
