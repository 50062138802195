import GoToTopIcon from 'components/SVGIcons/GoToTopIcon';
import { ButtonWrapper } from './JumpToTheTopButton.styled';
import { useEffect, useState } from 'react';
import { theme } from 'theme/theme';

const JumpToTheTopButton = () => {
    const [showButton, setShowButton] = useState(false);
    const buttonColor = theme.colors.primaryColor;

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleButtonClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (showButton) {
        return (
            <ButtonWrapper>
                <GoToTopIcon
                    onClick={handleButtonClick}
                    width={40}
                    height={40}
                    color={buttonColor}
                    style={{ cursor: 'pointer' }}
                />
            </ButtonWrapper>
        );
    }

    return null;
};

export default JumpToTheTopButton;
