import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
    display: 'flex',
    width: '90%',
    marginTop: '35px',
}));

export const Img = styled('img')(() => ({
    width: 'auto',
    height: 'auto',
}));
