import { RichTextDefaultWrapper } from './DefaultStylesRichText.style';

interface Props {
    className?: string;
}

const DefaultStylesRichText: React.FC<Props> = ({ children, className }) => {
    return (
        <RichTextDefaultWrapper className={className}>
            {children}
        </RichTextDefaultWrapper>
    );
};

export default DefaultStylesRichText;
