import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';

export const Background = styled('div')(({ theme }) => ({
    maxWidth: theme.breakpoints.values.desktop,
    backgroundColor: theme.colors.primaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '345px',
    padding: '63px 174px 63px 96px',

    [mobileStyles]: {
        padding: '26px 36px',
    },
}));

export const ContentBlock = styled('div')(() =>
    mq({
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    })
);

export const Details = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        width: ['100%', '71%'],
    })
);

export const Title = styled('h2')(({ theme }) =>
    mq({
        ...theme.fonts.h2_headline,
        color: theme.colors.white,

        [mobileStyles]: {
            ...theme.fonts.h3_headline,
        },
    })
);

export const Description = styled('div')(({ theme }) =>
    mq({
        ...theme.fonts.body_2,
        color: theme.colors.white,
        marginTop: '31px',

        '& p': {
            marginTop: '0px!important',
        },
    })
);

export const Img = styled('img')(() =>
    mq({
        cursor: 'pointer',
        display: ['none', 'block'],
        maxWidth: '235px',
        maxHeight: '235px',
    })
);

export const Buttons = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '20px',
    marginTop: '30px',

    [mobileStyles]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '9px',
    },

    '& .decorativeLink': {
        width: 'auto!important',

        '& p, img': {
            filter: 'brightness(0) invert(1)',
        },
    },

    '& .action': {
        width: 'auto!important',
        marginTop: '0px!important',
    },
}));
