import { ComponentStyle } from 'types/constantTypes';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const ChessContainer = styled('div')<{
    $offset?: boolean;
    $sectionStyle?: string;
}>(({ $offset = false, $sectionStyle }) =>
    mq({
        '& > div:nth-of-type(1)': {
            marginTop: '0',
            paddingTop: '20px',
        },

        '& > div:nth-of-type(even)': {
            margin: $offset
                ? ['0 auto', ' 0 32px 0 auto', '0 48px 0 auto']
                : '0 auto',

            flexDirection:
                $sectionStyle === ComponentStyle.customIllustrationChessBlock
                    ? ['column-reverse', 'row-reverse', 'row-reverse']
                    : ['column-reverse', 'row', 'row'],

            '& .right-content': {
                justifyContent: ['center', 'center', 'flex-start'],
            },
        },

        '& > div:nth-of-type(odd)': {
            flexDirection:
                $sectionStyle === ComponentStyle.customIllustrationChessBlock
                    ? ['column-reverse', 'row', 'row']
                    : ['column-reverse', 'row-reverse', 'row-reverse'],
            margin: $offset
                ? ['0 auto', ' 0 auto 0 32px', '0 auto 0 48px']
                : '0 auto',
        },
    })
);

export const ChessTitle = styled('div')(({ theme }) => ({
    ...theme.fonts.h3_headline,
    color: theme.colors.primaryText,
    textAlign: 'center',
}));
