import { styled } from '@mui/material';

export const ButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'sticky',
    maxWidth: theme.breakpoints.values.desktop,
    top: '90vh',
    alignSelf: 'end',
    marginRight: '3%',
    zIndex: '10',
}));
