import { CardsWrapper } from './WithoutBordersCards.styled';
import WithoutBordersCard from './WithoutBordersCard';
import type { WithoutBordersCards } from 'types/componentTypes';

const WithoutBordersCards: React.FC<WithoutBordersCards> = ({ cards }) => {
    const WithoutBordersCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <WithoutBordersCard
                key={card.id}
                {...card}
                lastItem={i === arrayLength - 1}
            />
        );
    });

    return <CardsWrapper className="cards">{WithoutBordersCards}</CardsWrapper>;
};

export default WithoutBordersCards;
