import useApiUrl from 'hooks/useApiUrl';
import { ComponentImages } from 'types/componentTypes';
import { ImagesWrapper, Img } from './Images.styled';

const Images: React.FC<ComponentImages> = ({ value }) => {
    const apiUrl = useApiUrl();

    return (
        <ImagesWrapper>
            {value.map((image, i) => {
                return <Img key={i} src={`${apiUrl}${image.url}`} />;
            })}
        </ImagesWrapper>
    );
};

export default Images;
