import { ComponentHero } from 'types/componentTypes';
import { ComponentStyle } from 'types/constantTypes';
import HomeBanner from './HomeStyle';
import LegalBanner from './LegalStyle';
import RegularBanner from './RegularStyle';
import Marketing from './MarketingStyle';
import BigIllustration from './BigIllustration';

const Hero: React.FC<ComponentHero> = ({ value }) => {
    if (!value) return null;

    switch (value.style) {
        case ComponentStyle.legal:
            return <LegalBanner {...value} />;

        case ComponentStyle.regular:
            return <RegularBanner {...value} />;

        case ComponentStyle.home:
            return <HomeBanner {...value} />;

        case ComponentStyle.marketingHome:
            return <Marketing {...value} />;

        case ComponentStyle.bigIllustration:
            return <BigIllustration {...value} />;

        default:
            return null;
    }
};

export default Hero;
