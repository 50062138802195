import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const CardsWrapper = styled('div')(() =>
    mq({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: ['35px auto 0', '90px auto 0', '90px auto 0'],
        gap: ['35px', '60px'],
    })
);
