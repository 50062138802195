import React from 'react';

interface IRawHtmlProps {
    html: string;
}

const RawHtml = ({ html }: IRawHtmlProps) => (
    <div dangerouslySetInnerHTML={{ __html: html }} />
);

export default RawHtml;
