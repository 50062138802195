import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const RichTextDefaultWrapper = styled(
    'div',
    transientOptions
)(({ theme }) =>
    mq({
        '&.centeredWithMargin': {
            textAlign: 'center',
            marginTop: ['35px', '55px', '95px'],
        },

        '& b': {
            fontWeight: 'bold',
        },

        '& i': {
            fontStyle: 'italic',
        },

        '& .location': {
            position: 'relative',
            marginLeft: '35px',
        },

        '& .location:before': {
            content: "url('/img/contactUs/locationIcon.svg')",
            position: 'absolute',
            left: '-35px',
        },

        '& h3': {
            ...theme.fonts.h4_headline,
            marginTop: '25px',
        },

        '& h4': {
            ...theme.fonts.h4_headline,
            marginTop: '25px',
        },

        '& h5': {
            ...theme.fonts.h5_headline,
            marginTop: '25px',
        },

        '& h6': {
            ...theme.fonts.h6_headline,
            marginTop: '25px',
        },

        '& p': {
            ...theme.fonts.body_2,
            marginTop: '25px',
        },

        '& ul': {
            padding: '15px 0px 35px',

            '& li': {
                marginBottom: '17px',
                marginLeft: '31px',
                position: 'relative',
            },

            '& li:before': {
                content: "url('/img/planArrow.svg')",
                position: 'absolute',
                left: '-31px',
            },

            '& li:nth-last-of-type(1)': {
                marginBottom: '0px',
            },
        },
    })
);
