import { RegularTabWrapper } from './RegularTab.styled';

interface RegularTabProps {
    isActive: boolean;
    title: string;
    handler: () => void;
}

const RegularTab: React.FC<RegularTabProps> = ({
    isActive,
    title,
    handler,
}) => {
    return (
        <RegularTabWrapper $isActive={isActive} onClick={handler}>
            {title}
        </RegularTabWrapper>
    );
};

export default RegularTab;
