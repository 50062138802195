import { CardsWrapper } from './BigCards.styled';
import BigCard from './BigCard/BigCard';
import type { BigCards } from 'types/componentTypes';

const BigCards: React.FC<BigCards> = ({ cards }) => {
    const bigCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <BigCard key={card.id} {...card} lastItem={i === arrayLength - 1} />
        );
    });

    return <CardsWrapper className="cards">{bigCards}</CardsWrapper>;
};

export default BigCards;
