import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';
import {
    DescriptionWrapperShared,
    MainContentWrapperShared,
} from 'components/Cards/Cards.shared.styled';

interface CardWrapperProps {
    $lastItem: boolean | undefined;
}

export const CardWrapper = styled(
    'div',
    transientOptions
)<CardWrapperProps>(({ $lastItem }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 30%',
    width: $lastItem ? '250px' : 'auto',

    [mobileStyles]: {
        margin: '5px 5px 13px 5px',
        width: '167px',
    },
}));

export const ButtonCardWrapper = styled('div')(() => ({
    cursor: 'pointer',
}));

export const CardBody = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: ['167px', '210px', '250px'],
        height: ['330px', '350px', '350px'],
        gap: '20px',
    })
);

export const Arrow = styled('img')(() =>
    mq({
        maxWidth: '80px',
        maxHeight: '80px',
        marginBottom: ['70px'],
    })
);

export const MainContentWrapper = styled(MainContentWrapperShared)(
    ({ $hasAction }) =>
        mq({
            height: $hasAction ? ['270px', '290px', '290px'] : '100%',
        })
);

export const ImgWrapper = styled('div')(() =>
    mq({
        height: ['134px', '140px'],
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })
);

export const Img = styled('img')(() => ({
    maxWidth: '100%',
    maxHeight: ['134px', '140px'],
}));

export const Title = styled('h6')(({ theme }) => {
    return mq({
        ...theme.fonts.h6_headline,
        color: theme.colors.primaryText,
        textAlign: 'center',
        margin: '20px 0',

        [mobileStyles]: {
            ...theme.fonts.h7_headline,
        },
    });
});

export const DescriptionWrapper = styled(DescriptionWrapperShared)(
    ({ theme }) =>
        mq({
            maxWidth: ['100%', '100%', '100%'],
            marginTop: ['10px', '8px', '15px'],
            textAlign: 'center',
            color: theme.colors.secondaryText,
        })
);
