import DefaultStylesRichText from 'components/DefaultStylesRichText';
import RawHtml from 'components/RawHtml';
import { ComponentRichText } from 'types/componentTypes';

const RichText: React.FC<ComponentRichText> = ({ value, className }) => {
    return (
        <DefaultStylesRichText className={className}>
            <RawHtml html={value} />
        </DefaultStylesRichText>
    );
};

export default RichText;
