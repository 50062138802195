import { styled } from '@mui/material';

export const FormContainer = styled('div')(({ theme }) => ({
    width: '642px',
    display: 'flex',
    padding: '35.5px',
    flexDirection: 'column',
    boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.15)',
    border: `1px solid ${theme.colors.secondaryColor_3}`,
    borderRadius: '20px',
    alignItems: 'center',

    marginTop: '50px',
}));

export const Form = styled('form')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginTop: '41px',
}));

export const AboutWrapper = styled('div')(({ theme }) => ({
    ...theme.fonts.body_4,
    color: theme.colors.secondaryText,
    textAlign: 'center',
}));

export const FormTitle = styled('h4')(({ theme }) => ({
    ...theme.fonts.h4_headline,
    color: theme.colors.primaryText,
}));

export const SendButton = styled('button')(({ theme, disabled }) => ({
    ...theme.fonts.button_text,
    color: theme.colors.primaryText,
    background: theme.colors.secondaryColor_basic,
    width: '100%',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: 'none',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    height: '44px',
}));
