import { customDotsMargin } from 'components/Carousel/customDotsMargin';
import { Settings } from 'react-slick';

export const createCustomSettings = (
    isMobile: boolean,
    partnersLength: number
) => {
    const settings: Settings = {
        dots: isMobile ? true : false,
        infinite: true,
        autoplaySpeed: 4000,
        autoplay: true,
        speed: 1000,
        slidesToShow: partnersLength < 4 ? partnersLength : 4,
        pauseOnHover: true,
        arrows: false,
        appendDots: (dots: React.FC) => customDotsMargin(30, dots),

        responsive: [
            {
                breakpoint: 1330,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return settings;
};
