import CustomLink from 'components/CustomLink';
import { ButtonCard, OrdinaryCard } from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';
import {
    CardWrapper,
    CardBody,
    Title,
    ButtonCardWrapper,
    MainContentWrapper,
    Arrow,
    ImgWrapper,
    TitleWrapper,
    Step,
    StepWrapper,
} from './CustomIllustrationCard.styled';
import useApiUrl from 'hooks/useApiUrl';
import { useDisplayType } from '@hooks/useDisplayType';
import Image from 'next/image';

export type CustomIllustrationCardProps = (OrdinaryCard | ButtonCard) & {
    cardNumber: number;
};

const CustomIllustrationCard: React.FC<CustomIllustrationCardProps> = ({
    value,
    type,
    cardNumber,
    lastItem,
}) => {
    const wagtailAPI = useApiUrl();
    const { isTablet, isDesktop } = useDisplayType();
    const withArrow = isTablet || isDesktop;
    const imageUrl = value?.illustration?.url;

    const OrdinaryCard = () => {
        return (
            <CardWrapper>
                <CardBody>
                    <MainContentWrapper>
                        {imageUrl && (
                            <ImgWrapper>
                                <Image
                                    src={`${wagtailAPI}${imageUrl}`}
                                    layout="fill"
                                    objectFit="contain"
                                    alt={value.illustration?.title}
                                />
                            </ImgWrapper>
                        )}

                        <TitleWrapper>
                            <Title>{value.title}</Title>
                        </TitleWrapper>
                    </MainContentWrapper>

                    <StepWrapper>
                        <Step>{cardNumber}</Step>
                    </StepWrapper>
                </CardBody>

                {!lastItem && withArrow && (
                    <Arrow
                        data-testid="card-arrow"
                        src="/img/steps/arrowCard.svg"
                    />
                )}
            </CardWrapper>
        );
    };

    if (type === ComponentType.buttonCard) {
        return (
            <CustomLink href={`${wagtailAPI}${value.action.action.url}`}>
                <ButtonCardWrapper>
                    <OrdinaryCard />
                </ButtonCardWrapper>
            </CustomLink>
        );
    }

    if (type === ComponentType.ordinaryCard) {
        return <OrdinaryCard />;
    }

    return null;
};

export default CustomIllustrationCard;
