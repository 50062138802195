import CustomLink from 'components/CustomLink';
import { ComponentAction } from 'types/componentTypes';
import { Button, Wrapper } from './Action.styled';

const Action: React.FC<ComponentAction> = ({ value }) => {
    return (
        <Wrapper className="action">
            <CustomLink href={value.action.url}>
                <Button $buttonColor={value.accentColor}>{value.title}</Button>
            </CustomLink>
        </Wrapper>
    );
};

export default Action;
