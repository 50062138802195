import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';
import { tabletStyles, mobileStyles } from 'types/displayTypeStyles';

interface RegularTabProps {
    $isActive?: boolean;
}
export const RegularTabWrapper = styled(
    'button',
    transientOptions
)<RegularTabProps>(({ theme, $isActive }) => {
    return mq({
        ...theme.fonts.h6_headline,
        width: '100%',
        maxWidth: '50%',
        height: ['81px', '60px', '79px'],
        cursor: 'pointer',

        background: $isActive ? theme.colors.primaryColor : 'transparent',
        border: $isActive ? 'none' : `1px solid ${theme.colors.secondaryText}`,
        borderRight: 'none',
        color: $isActive ? theme.colors.white : theme.colors.secondaryText,

        [tabletStyles]: {
            ...theme.fonts.h7_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.body_3,
            maxWidth: '169px',
            borderRadius: $isActive ? '20px 20px 3px 3px' : '0px',
            border: 'none',

            borderWidth: $isActive ? '1px 1px 0px 1px' : '0px 0px 0px 0px',
            borderColor: theme.colors.secondaryColor_2,
            borderStyle: 'solid',

            borderBottom: $isActive
                ? `8px solid ${theme.colors.primaryColor}`
                : `1px solid ${theme.colors.secondaryColor_2}`,
            marginLeft: '4px',
            background: 'none',
            padding: '25px 36px',

            color: $isActive
                ? theme.colors.primaryText
                : theme.colors.secondaryText,
        },
    });
});
