import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const CardsWrapper = styled('div')(() =>
    mq({
        display: 'flex',
        justifyContent: 'center',
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: ['37px', '44px', '45px'],
        gap: '20px',
    })
);
