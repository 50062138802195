import {
    Background,
    Content,
    ContentL,
    ContentR,
    Description,
    Img,
    Title,
    ActionWrapper,
} from './CroppedPhotoBlock.styled';

import type {
    ComponentInternalLink,
    ComponentExternalLink,
    ComponentRichText,
    CroppedPhotoBlock,
    ComponentAction,
} from 'types/componentTypes';
import RichText from 'components/RichText';
import DecorativeLink from 'components/DecorativeLink';
import Action from 'components/Action';
import useApiUrl from 'hooks/useApiUrl';

const CroppedPhotoBlock: React.FC<CroppedPhotoBlock> = ({
    image,
    content,
    title,
}) => {
    const apiUrl = useApiUrl();

    const componentsLayout = {
        rich_text: (component: ComponentRichText) => (
            <Description>
                <RichText {...component} />
            </Description>
        ),

        action: (component: ComponentAction) => (
            <ActionWrapper>
                <Action {...component} />
            </ActionWrapper>
        ),

        internal_link: (component: ComponentInternalLink) => (
            <DecorativeLink {...component} />
        ),

        external_link: (component: ComponentExternalLink) => (
            <DecorativeLink {...component} />
        ),
    };

    return (
        <Background $imagePosition={image.alignment}>
            <ContentL>
                <Img
                    src={`${apiUrl}${image.url}`}
                    $imagePosition={image.alignment}
                />
            </ContentL>

            <ContentR $imagePosition={image.alignment}>
                <Content>
                    <Title>{title}</Title>

                    {content.map((component) => {
                        if (component.type) {
                            const Component = componentsLayout[
                                component.type
                            ] as React.FC;

                            return (
                                <Component key={component.id} {...component} />
                            );
                        }

                        return null;
                    })}
                </Content>
            </ContentR>
        </Background>
    );
};

export default CroppedPhotoBlock;
