import { mq } from 'theme/mediaQueries';
import { styled } from '@mui/material';

export const ImagesWrapper = styled('div')(() =>
    mq({
        width: '100%',
        display: 'inline-flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: ['26px', '39px', '47px'],
    })
);

export const Img = styled('img')(() => ({
    width: 'auto',
    height: 'auto',
}));
