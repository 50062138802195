import {
    Background,
    CarouselBlock,
    MobileWrapper,
} from './ReviewSlider.styled';
import Carousel from 'components/Carousel';
import CardFeedback from './CardFeedback';
import React from 'react';
import { useDisplayType } from 'hooks/useDisplayType';
import CardFeedbackMobile from './CardFeedbackMobile';
import { createCustomSettings } from './createCustomSettings';
import type { ReviewSlider } from 'types/componentTypes';

const ReviewSlider: React.FC<ReviewSlider> = ({ value }) => {
    const { isMobile, isDesktop } = useDisplayType();
    const { settings, additionalMobileSettings } =
        createCustomSettings(isDesktop);

    return isMobile ? (
        <MobileWrapper>
            <Carousel settings={{ ...settings, ...additionalMobileSettings }}>
                {value.reviews.map((review) => {
                    return (
                        <CardFeedbackMobile key={review.id} review={review} />
                    );
                })}
            </Carousel>
        </MobileWrapper>
    ) : (
        <Background>
            <CarouselBlock>
                <Carousel settings={settings}>
                    {value.reviews.map((review) => {
                        return <CardFeedback key={review.id} review={review} />;
                    })}
                </Carousel>
            </CarouselBlock>
        </Background>
    );
};

export default ReviewSlider;
