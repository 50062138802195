import { customDotsMargin } from 'components/Carousel/customDotsMargin';
import { Settings } from 'react-slick';

export const createCustomSettings = (isDesktop: boolean) => {
    const settings: Settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        pauseOnHover: true,
        arrows: false,
        appendDots: (dots: React.FC) =>
            isDesktop ? customDotsMargin(60, dots) : customDotsMargin(44, dots),
    };

    const additionalMobileSettings = {
        slidesToShow: 2,
        pauseOnHover: true,
        appendDots: (dots: React.FC) => customDotsMargin(38, dots),
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return { settings, additionalMobileSettings };
};
