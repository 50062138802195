import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

interface ImageProps {
    $imagePosition: string;
}

export const Background = styled(
    'div',
    transientOptions
)<ImageProps>(({ theme, $imagePosition }) =>
    mq({
        background: theme.colors.secondaryColor_3,
        borderRadius: '150px 0px 110px',
        width: '100%',
        height: '550px',
        display: 'flex',
        flexDirection: $imagePosition === 'right' ? 'row-reverse' : 'row',
        marginTop: ['70px', '80px', '130px'],

        [tabletStyles]: {
            height: 'auto',
            flexDirection: 'row',
        },

        [mobileStyles]: {
            borderRadius: '50px',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
        },
    })
);

export const ContentL = styled('div')(() =>
    mq({
        width: '41%',
        height: '100%',
        display: ['none', 'none', 'block'],
    })
);

export const Img = styled(
    'img',
    transientOptions
)<ImageProps>(({ $imagePosition }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius:
        $imagePosition === 'right'
            ? '300px 0px 0px 300px'
            : '0px 300px 300px 0px;',
}));

export const ContentR = styled('div')<ImageProps>(({ $imagePosition }) =>
    mq({
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '70px 0px',
        width: ['90%', '100%', '36%'],

        margin:
            $imagePosition === 'right'
                ? ['0px', '0px', '0px 80px 0px 0px']
                : ['0px', '0px', '0px 00px 0px 80px'],

        [tabletStyles]: {
            marginLeft: '136px',
        },

        [mobileStyles]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '60px 0px',
        },
    })
);

export const Content = styled('div')(() =>
    mq({
        maxWidth: '520px',

        display: 'flex',
        flexDirection: 'column',

        [mobileStyles]: {
            maxWidth: '90%',
        },

        '& button': {
            [mobileStyles]: {
                margin: '0 auto',
            },
        },
    })
);

export const ActionWrapper = styled('div')({
    [mobileStyles]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export const Title = styled('div')(({ theme }) =>
    mq({
        ...theme.fonts.h4_headline,
    })
);

export const Description = styled('div')(({ theme }) =>
    mq({
        ...theme.fonts.body_2,
        marginTop: '25px',

        '& p': {
            marginTop: '0px!important',
        },

        '& ul': {
            marginTop: ['45px!important', '25px!important', '25px!important'],

            '& li': {
                marginBottom: '25px!important',
            },
            '& li:nth-last-of-type(1)': {
                marginBottom: '0px!important',
            },

            '& li:before': {
                content: "url('/img/li_dot.svg')!important",
                position: 'absolute',
                top: '-2px',
            },
        },
    })
);
