import Carousel from 'components/Carousel';
import { useDisplayType } from 'hooks/useDisplayType';
import { CarouselWrapper } from './PartnerList.styled';
import { createCustomSettings } from './carouselCustomSettings';
import ProfessionalsCard from './ProfessionalsCard';
import type { PartnerList } from 'types/componentTypes';

const PartnerList: React.FC<PartnerList> = ({ value }) => {
    const { isMobile } = useDisplayType();

    const settings = createCustomSettings(isMobile, value.partners.length);

    return (
        <CarouselWrapper>
            <Carousel settings={settings}>
                {value.partners.map((partner) => {
                    return (
                        <ProfessionalsCard key={partner.id} partner={partner} />
                    );
                })}
            </Carousel>
        </CarouselWrapper>
    );
};

export default PartnerList;
