import { ActionButton } from 'components/Cards/Cards.shared.styled';
import CustomLink from 'components/CustomLink';
import RichText from 'components/RichText';
import useApiUrl from 'hooks/useApiUrl';
import { useDisplayType } from 'hooks/useDisplayType';
import { ButtonCard, OrdinaryCard } from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';
import {
    CardWrapper,
    CardBody,
    Img,
    Title,
    Arrow,
    DescriptionWrapper,
    ButtonCardWrapper,
    ImgWrapper,
    MainContentWrapper,
} from './WavyArrowsCard.styled';

const WavyArrowsCard: React.FC<OrdinaryCard | ButtonCard> = ({
    value,
    lastItem,
    type,
}) => {
    const apiUrl = useApiUrl();
    const { isTablet, isDesktop } = useDisplayType();
    const withArrow = isTablet || isDesktop;
    const hasAction = !!(
        type === ComponentType.ordinaryCard && value.action?.action?.url
    );

    const OrdinaryCard = () => {
        return (
            <CardWrapper $lastItem={lastItem}>
                <CardBody>
                    <MainContentWrapper $hasAction={hasAction}>
                        {value.illustration && (
                            <ImgWrapper>
                                <Img
                                    src={`${apiUrl}${value.illustration.url}`}
                                />
                            </ImgWrapper>
                        )}

                        <Title>{value.title}</Title>
                        <DescriptionWrapper>
                            <RichText value={value.description} />
                        </DescriptionWrapper>
                    </MainContentWrapper>

                    {hasAction && (
                        <CustomLink href={value.action?.action?.url}>
                            <ActionButton $color={value.action?.accentColor}>
                                {value?.action?.title}
                            </ActionButton>
                        </CustomLink>
                    )}
                </CardBody>

                {!lastItem && withArrow && (
                    <Arrow src="/img/steps/WavyArrow.svg" className="arrow" />
                )}
            </CardWrapper>
        );
    };

    if (type === ComponentType.buttonCard) {
        return (
            <CustomLink href={`${apiUrl}${value.action.action.url}`}>
                <ButtonCardWrapper>
                    <OrdinaryCard />
                </ButtonCardWrapper>
            </CustomLink>
        );
    }

    if (type === ComponentType.ordinaryCard) {
        return <OrdinaryCard />;
    }

    return null;
};

export default WavyArrowsCard;
