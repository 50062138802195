import DefaultStylesRichText from 'components/DefaultStylesRichText';
import RawHtml from 'components/RawHtml';
import { ComponentHeroValues } from 'types/componentTypes';
import {
    Buttons,
    Content,
    ContentL,
    ContentR,
    Title,
    Icon,
    ImgL,
    ImgR,
    Description,
} from './Home.styled';
import useApiUrl from 'hooks/useApiUrl';
import Image from 'next/image';
import referenceComponentsMap from '../referenceComponentsMap';

const HomeBanner: React.FC<ComponentHeroValues> = ({
    title,
    description,
    references,
    images,
}) => {
    const apiUrl = useApiUrl();

    const withReferences = !!references.length;

    const imagesBlock = images.map((image, i) => {
        if (i === 0) {
            return (
                <ImgL key={i}>
                    <Image
                        alt={image.title}
                        src={`${apiUrl}${image.url}`}
                        layout="fill"
                        objectFit="cover"
                        priority
                    />
                </ImgL>
            );
        }

        if (i === 1) {
            return (
                <ImgR key={i}>
                    <Image
                        src={`${apiUrl}${image.url}`}
                        alt={image.title}
                        layout="fill"
                        objectFit="cover"
                    />
                </ImgR>
            );
        }

        return (
            <Icon key={i}>
                <Image
                    alt={image.title}
                    src={`${apiUrl}${image.url}`}
                    layout="responsive"
                    width="100%"
                    height="100%"
                />
            </Icon>
        );
    });

    const buttonsBlock = references.map((component) => {
        const componentKey = component.type as keyof typeof referenceComponentsMap;

        const Component = referenceComponentsMap[componentKey] as React.FC;

        if (!Component) return null;

        return <Component key={component.id} {...component} />;
    });

    return (
        <Content>
            <ContentL>
                <Title data-testid="home">{title}</Title>

                <DefaultStylesRichText>
                    <Description>
                        <RawHtml html={description} />
                    </Description>
                </DefaultStylesRichText>

                {withReferences && <Buttons>{buttonsBlock}</Buttons>}
            </ContentL>

            <ContentR>{imagesBlock}</ContentR>
        </Content>
    );
};

export default HomeBanner;
