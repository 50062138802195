import { pxToRem } from './../../../../helpers/pxToRem';
import RichText from 'components/RichText';
import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';
import { ComponentType } from 'types/constantTypes';
import {
    DescriptionWrapperShared,
    MainContentWrapperShared,
} from 'components/Cards/Cards.shared.styled';

interface CardWrapperProps {
    $type: ComponentType;
}

export const CardWrapper = styled(
    'div',
    transientOptions
)<CardWrapperProps>(({ $type }) =>
    mq({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: ['90%', 'auto'],
        cursor: $type === ComponentType.buttonCard && 'pointer',

        [mobileStyles]: {
            margin: '5px 5px 13px 5px',
        },
    })
);

export const CardBody = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: '35px 35px 40px',
        width: ['305px', '345px', '370px'],
        height: ['485px', '470px', '480px'],
        boxShadow: '0px 4px 20px rgba(17, 24, 33, 0.25)',
        borderRadius: '20px 20px 4px 4px',
    })
);

export const MainContentWrapper = styled(MainContentWrapperShared)(
    ({ $hasAction }) =>
        mq({
            height: $hasAction ? ['355px', '340px', '350px'] : '100%',
        })
);

export const ImgWrapper = styled('div')(() =>
    mq({
        height: ['165px', '190px'],
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    })
);

export const Img = styled('img')(() => ({
    maxHeight: ['165px', '190px'],
    maxWidth: '100%',
}));

export const Title = styled('h5')(({ theme }) => {
    return mq({
        ...theme.fonts.h4_headline,
        color: theme.colors.primaryText,
        textAlign: 'center',
        margin: '20px 0',
    });
});

export const DescriptionWrapper = styled(DescriptionWrapperShared)(() =>
    mq({
        maxWidth: ['100%', '100%', '100%'],
    })
);

export const StyledRichText = styled(RichText)(() =>
    mq({
        fontSize: pxToRem(16),

        '& p': {
            marginTop: '0',
        },
    })
);
