import componentsMap from 'components/componentsMap';
import GrayScale from 'components/StyledSection/GrayScale';
import type {
    StyledSection,
} from 'types/componentTypes';
import { ComponentStyle } from 'types/constantTypes';
import {
    Blank,
    BlankTitle,
    Title,
} from './StyledSection.styled';
import { matchString } from 'utils/matchString';
import ChessBlock from './ChessBlock/ChessBlock';

const StyledSection: React.FC<StyledSection> = ({ value }) => {
    if (value.style === ComponentStyle.grayscale) {
        return (
            <GrayScale>
                <Title>{value.title}</Title>

                {value.content.map((component) => {
                    const componentKey =
                        component.type as keyof typeof componentsMap;

                    const Component = componentsMap[componentKey] as React.FC;

                    if (Component) {
                        return <Component key={component.id} {...component} />;
                    }
                    return null;
                })}
            </GrayScale>
        );
    }

    if (value.style === ComponentStyle.blank) {
        return (
            <Blank>
                <BlankTitle>{value.title}</BlankTitle>

                {value.content.map((component) => {
                    const componentKey =
                        component.type as keyof typeof componentsMap;

                    const Component = componentsMap[componentKey] as React.FC;

                    if (Component) {
                        return <Component key={component.id} {...component} />;
                    }
                    return null;
                })}
            </Blank>
        );
    }

    if (
        matchString(value.style, [
            ComponentStyle.mediumChessBlock,
            ComponentStyle.bigChessBlock,
            ComponentStyle.smallChessBlock,
            ComponentStyle.offsetChessBlock,
            ComponentStyle.customIllustrationChessBlock,
        ])
    ) {
        return (
            <ChessBlock value={value}/>
        );
    }

    return null;
};

export default StyledSection;
