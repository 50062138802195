import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

interface MultipleSection {
    $oddAndLast: boolean;
}

export const MultipleSection = styled(
    'div',
    transientOptions
)<MultipleSection>(({ $oddAndLast }) => ({
    width: '347px',
    minHeight: '338px',

    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0px',
    position: 'relative',

    [tabletStyles]: {
        margin: $oddAndLast ? '30px 40px!important' : '30px 0px',
    },

    [mobileStyles]: {
        width: '100%',
        minWidth: '287px',
        maxWidth: '347px',
        marginBottom: '83px',
    },
}));

export const Title = styled('div')(({ theme }) => ({
    ...theme.fonts.h3_headline,
    color: theme.colors.white,
}));

export const Description = styled('div')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.white,
    marginTop: '30px',

    '& p': {
        marginTop: '0px!important',
    },
}));

export const Button = styled('button')(({ theme }) => {
    return {
        padding: '12px 47px',
        ...theme.fonts.button_text,
        width: '100%',
        background: theme.colors.white,
        borderRadius: '50px',
        color: theme.colors.primaryText,
        border: 'none',
        cursor: 'pointer',
    };
});
