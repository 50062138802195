import Action from 'components/Action';
import DecorativeLink from 'components/DecorativeLink';
import RichText from 'components/RichText';
import useApiUrl from 'hooks/useApiUrl';
import {
    ComponentInfographics,
    ComponentAction,
    ComponentRichText,
    ComponentInternalLink,
    ComponentExternalLink,
} from 'types/componentTypes';
import {
    Background,
    BodyL,
    BodyR,
    Title,
    Img,
    Description,
} from './Infographics.styled';
import Image from 'next/image';

const Infographics: React.FC<ComponentInfographics> = ({
    title,
    image,
    content,
}) => {
    const apiUrl = useApiUrl();

    const layout = {
        rich_text: (component: ComponentRichText) => (
            <Description>
                <RichText {...component} />
            </Description>
        ),

        action: (component: ComponentAction) => <Action {...component} />,

        internal_link: (component: ComponentInternalLink) => (
            <DecorativeLink {...component} />
        ),

        external_link: (component: ComponentExternalLink) => (
            <DecorativeLink {...component} />
        ),
    };

    return (
        <Background>
            <BodyL>
                <Title>{title}</Title>

                {content.map((component) => {
                    if (component.type) {
                        const Component = layout[component.type] as React.FC;

                        return (
                            <Component
                                key={component.id}
                                {...component}></Component>
                        );
                    }

                    return null;
                })}
            </BodyL>

            <BodyR>
                <Image
                    src={`${apiUrl}/${image.url}`}
                    layout="fill"
                    objectFit="contain"
                />
                <Img src={`${apiUrl}/${image.url}`} />
            </BodyR>
        </Background>
    );
};

export default Infographics;
