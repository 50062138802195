import { ComponentCards } from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';
import BigCards from './BigCards';
import MediumCards from './MediumCards';
import { Wrapper } from './Cards.styled';
import TriangularArrowCards from './TriangularArrowCards';
import WavyArrowsCards from './WavyArrowsCards';
import GridCards from './GridCards';
import SmallCards from './SmallCards';
import WithoutBordersCards from './WithoutBordersCards';
import CustomIllustrationCards from './CustomIllustrationCards';

const Cards: React.FC<ComponentCards> = ({ value }) => {
    if (value.style === ComponentType.triangularArrowsCards) {
        return (
            <Wrapper>
                <TriangularArrowCards {...value} />
            </Wrapper>
        );
    }
    if (value.style === ComponentType.customIllustrationCards) {
        return (
            <Wrapper>
                <CustomIllustrationCards {...value} />
            </Wrapper>
        );
    }
    if (value.style === ComponentType.bigCards) {
        return (
            <Wrapper>
                <BigCards {...value} />
            </Wrapper>
        );
    }
    if (value.style === ComponentType.mediumCards) {
        return (
            <Wrapper>
                <MediumCards {...value} />
            </Wrapper>
        );
    }
    if (value.style === ComponentType.smallCards) {
        return (
            <Wrapper>
                <SmallCards {...value} />
            </Wrapper>
        );
    }
    if (value.style === ComponentType.wavyArrowsCards) {
        return (
            <Wrapper>
                <WavyArrowsCards {...value} />
            </Wrapper>
        );
    }
    if (value.style === ComponentType.gridCards) {
        return (
            <Wrapper>
                <GridCards {...value} />
            </Wrapper>
        );
    }
    if (value.style === ComponentType.withoutBordersCards) {
        return (
            <Wrapper>
                <WithoutBordersCards {...value} />
            </Wrapper>
        );
    }
    return null;
};

export default Cards;
