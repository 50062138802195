import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const CardsWrapper = styled('div')(() =>
    mq({
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: [
            '167px 167px',
            '300px 300px 210px',
            '330px 330px 250px',
        ],
        gap: ['18px', '10px'],
        margin: ['35px auto 0', '90px auto 0', '90px auto 0'],

        '& div:nth-of-type(even) .arrow': {
            transform: 'rotateX(-180deg)',
        },
    })
);
