import { customDotsMargin } from 'components/Carousel/customDotsMargin';
import { Settings } from 'react-slick';

export const carouselCustomSettings = (isMobile: boolean) => {
    const settings: Settings = {
        dots: isMobile ? true : false,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        pauseOnHover: true,
        arrows: false,
        appendDots: (dots: React.FC) => customDotsMargin(30, dots),

        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return settings;
};
