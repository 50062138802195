import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';
import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { ButtonColors } from 'types/componentTypes';

interface ButtonProps {
    $buttonColor?: ButtonColors;
}

export const Wrapper = styled('div')({
    width: '90%',
    display: 'flex',
    marginTop: '40px',
});

export const Button = styled(
    'button',
    transientOptions
)<ButtonProps>(({ theme, $buttonColor }) => {
    const backgroundColor = (): string => {
        switch ($buttonColor) {
            case 'blank':
                return 'transparent';

            case 'primary':
                return theme.colors.primaryColor as string;

            case 'secondary':
                return theme.colors.secondaryColor_basic as string;

            default:
                return theme.colors.primaryColor as string;
        }
    };

    const textColor = (): string => {
        switch ($buttonColor) {
            case 'blank':
                return theme.colors.primaryColor as string;

            case 'primary':
                return theme.colors.white as string;

            case 'secondary':
                return theme.colors.primaryText as string;

            default:
                return theme.colors.white as string;
        }
    };

    return {
        ...theme.fonts.button_text,
        padding: '12px 35px',
        width: 'fit-content',
        background: backgroundColor(),

        borderRadius: '50px',
        color: textColor(),
        cursor: 'pointer',
        border:
            backgroundColor() === 'transparent'
                ? `1px solid ${theme.colors.primaryColor}`
                : 'none',

        [tabletStyles]: {
            padding: '16px 37px',
        },

        [mobileStyles]: {
            padding: '16px 27px',
        },
    };
});
