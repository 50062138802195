import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';
import {
    DescriptionWrapperShared,
    MainContentWrapperShared,
} from 'components/Cards/Cards.shared.styled';

export const CardWrapper = styled(
    'div',
    transientOptions
)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [mobileStyles]: {
        margin: '5px 5px 13px 5px',
    },
}));

export const ButtonCardWrapper = styled('div')(() => ({
    cursor: 'pointer',
}));

export const CardBody = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: ['45px 20px 35px', '40px 10px 40px', '60px 20px 40px'],
        width: ['305px', '235px', '275px'],
        height: ['403px', '350px', '375px'],
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px 20px 4px 4px',
    })
);

export const Arrow = styled('img')(() =>
    mq({
        margin: ['0px 6px', '0px 6px', '0px 20px'],
        maxWidth: '21px',
        maxHeight: '15',
    })
);

export const MainContentWrapper = styled(MainContentWrapperShared)(
    ({ $hasAction }) =>
        mq({
            height: $hasAction ? ['305px', '255px', '280px'] : '100%',
        })
);

export const ImgWrapper = styled('div')(() =>
    mq({
        height: ['165px', '137px'],
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    })
);

export const TitleWrapper = styled('div')(() => ({
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 0px',
}));

export const Title = styled('span')(({ theme }) => {
    return mq({
        ...theme.fonts.h5_headline,
        color: theme.colors.primaryText,

        [tabletStyles]: {
            ...theme.fonts.h6_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.h5_headline,
        },
    });
});

export const DescriptionWrapper = styled(DescriptionWrapperShared)(() =>
    mq({
        maxWidth: ['75%', '95%', '85%'],
        margin: '0 auto',
        textAlign: 'center',
    })
);
