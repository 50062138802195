import useApiUrl from 'hooks/useApiUrl';
import {
    Illustration,
    IllustrationWrapper,
    ImagedTabWrapper,
    Label,
    LabelWrapper,
} from './ImagedTab.styled';

interface ImagedTabProps {
    handler: () => void;
    isActive: boolean;
    url: string;
    title: string;
}

const ImagedTab: React.FC<ImagedTabProps> = ({
    handler,
    isActive,
    url,
    title,
}) => {
    const apiUrl = useApiUrl();

    return (
        <ImagedTabWrapper $isActive={isActive} onClick={handler}>
            <LabelWrapper>
                <Label $isActive={isActive}>{title}</Label>
            </LabelWrapper>

            <IllustrationWrapper>
                <Illustration src={`${apiUrl}${url}`} />
            </IllustrationWrapper>
        </ImagedTabWrapper>
    );
};

export default ImagedTab;
