import { transientOptions } from 'helpers/transientOptions';
import { mobileStyles } from 'types/displayTypeStyles';
import { styled } from '@mui/material/styles';
import { mq } from 'theme/mediaQueries';
import { ComponentColor } from 'types/constantTypes';

export const CardWrapper = styled('div')(({ theme }) =>
    mq({
        width: ['265px', '265px', '369px'],
        height: ['654px', '654px', '636px'],
        background: theme.colors.white,
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
        borderRadius: '20px 42px 18px 18px',
        padding: ['35px 17px', '35px 17px', '41px 25px 37px 25px'],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    })
);

export const ButtonWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const Title = styled('h4')(({ theme }) => ({
    ...theme.fonts.h4_headline,
}));

export const DescriptionRichText = styled('div')(({ theme }) => ({
    ...theme.fonts.body_4,
    marginTop: '24px',
    minHeight: '98px',

    [mobileStyles]: {
        minHeight: '0px',
    },

    '& p': {
        marginBottom: '15px',
    },

    '& p:nth-last-of-type(1)': {
        marginBottom: '0px',
    },
}));

export const PercentBackground = styled('div')(({ theme }) => ({
    width: '100%',
    height: '70px',
    background: theme.colors.primaryColor_4,
    borderRadius: '100px',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const PrimaryText = styled('div')(({ theme }) => ({
    ...theme.fonts.h3_headline,
    color: theme.colors.primaryColor,
}));

export const SecondaryText = styled('div')(({ theme }) => ({
    ...theme.fonts.h7_headline,
    color: theme.colors.primaryColor,
    marginLeft: '16px',
}));

export const FeaturesRichText = styled('div')(({ theme }) => ({
    ...theme.fonts.body_2,
}));

interface ButtonProps {
    $buttonColor: ComponentColor.primary | ComponentColor.secondary;
}

export const Button = styled(
    'button',
    transientOptions
)<ButtonProps>(({ theme, $buttonColor }) =>
    mq({
        ...theme.fonts.button_text,
        color:
            $buttonColor === ComponentColor.secondary
                ? theme.colors.primaryText
                : theme.colors.white,
        backgroundColor:
            $buttonColor === ComponentColor.secondary
                ? theme.colors.secondaryColor_basic
                : theme.colors.primaryColor,

        border: 'none',
        width: ['230px', '230px', '255px'],
        height: '48px',
        borderRadius: '50px',
        cursor: 'pointer',
    })
);
