import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

export const CardsWrapper = styled('div')(() =>
    mq({
        display: 'grid',
        gridTemplateColumns: [
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
        ],
        gap: ['35px', '40px'],
        width: 'fit-content',
        margin: ['35px auto 0', '90px auto 0', '90px auto 0'],
    })
);
