import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import axiosInstance from './axiosConfig';
import { Dispatch, SetStateAction } from 'react';

export interface Params {
    formData: FormData;
    url: string;
    setOnProgress?: Dispatch<SetStateAction<number>>;
}

const sendFormData = <T>({ formData, url, setOnProgress }: Params) => {
    const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (!progressEvent.total) return;

            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );

            setOnProgress && setOnProgress(percentCompleted);
            return percentCompleted;
        },
    };

    return axiosInstance.post<T>(url, formData, config);
};

export default sendFormData;
