import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

interface ContentProps {
    $withReferences: boolean;
}

export const Wrapper = styled('div')<ContentProps>(
    ({ theme, $withReferences }) =>
        mq({
            width: '95%',
            height: $withReferences
                ? ['437px', '437px', '619px']
                : ['400px', '400px', '455px'],
            background: theme.colors.primaryColor,
            borderRadius: [
                '10px',
                '10px 10px 134px 10px',
                '10px 10px 172px 10px',
            ],
            padding: '15px 15px 15px 50px',
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'hidden',

            alignItems: 'center',

            [mobileStyles]: {
                padding: '34px 32px',
                width: '91%',
            },
        })
);

export const ContentL = styled(
    'div',
    transientOptions
)<ContentWrapperProps>(({ $withReferences }) =>
    mq({
        display: 'flex',
        justifyContent: $withReferences ? 'space-between' : 'center',

        height: ['100%', '77%', '71%'],
        flexDirection: 'column',
        width: '45%',

        [mobileStyles]: {
            width: '100%',
        },
    })
);

interface ContentWrapperProps {
    $withReferences: boolean;
}

export const Title = styled('h2')(({ theme }) => ({
    ...theme.fonts.h2_headline,
    color: theme.colors.white,

    [tabletStyles]: {
        ...theme.fonts.h3_headline,
    },

    [mobileStyles]: {
        ...theme.fonts.h4_headline,
    },
}));

export const Description = styled('div')(({ theme }) =>
    mq({
        ...theme.fonts.body_2,
        color: theme.colors.white,
        marginTop: ['25px', '25px', '35px'],

        '& p': {
            marginTop: '0px!important',
        },
    })
);

export const ContentR = styled('div')(() => ({
    width: '51.1%',
    height: '100%',

    [mobileStyles]: {
        display: 'none',
    },
}));

export const ImgWrapper = styled('div')(() =>
    mq({
        position: 'relative',
        borderRadius: [
            '10px 10px 130px 10px',
            '10px 10px 130px 10px',
            '10px 10px 170px 10px',
        ],
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    })
);

export const Buttons = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '20px',

    [mobileStyles]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '9px',
        marginTop: '30px',
    },

    '& .decorativeLink': {
        width: 'auto!important',

        '& p, img': {
            filter: 'brightness(0) invert(1)',
        },
    },

    '& .action': {
        width: 'auto!important',
        marginTop: '0px!important',
    },
}));
