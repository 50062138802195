import { ParentStyle } from './../../../types/componentTypes';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';
import { ComponentStyle } from 'types/constantTypes';

export const Body = styled('div')<{ $parentStyle: ParentStyle }>(
    ({ $parentStyle }) =>
        mq({
            position: 'relative',
            display: 'flex',
            flexDirection: ['column-reverse', 'row', 'row'],
            height: '100%',
            alignItems: 'center',
            width:
                $parentStyle === ComponentStyle.customIllustrationChessBlock
                    ? ['100%', '100%', '100%']
                    : ['100%', '72%', '65%'],
            padding:
                $parentStyle !== ComponentStyle.offsetChessBlock
                    ? ['70px 0 0', '95px 0 0', '95px 0 0']
                    : ['40px 0 0', '50px 0 0', '50px 0 0'],

            margin: '0 auto',
            justifyContent: 'center',
            gap:
                $parentStyle === ComponentStyle.bigChessBlock ||
                $parentStyle === ComponentStyle.mediumChessBlock
                    ? '60px'
                    : $parentStyle === ComponentStyle.offsetChessBlock
                    ? '80px'
                    : ['10px', '40px', '40px'],
        })
);

export const ContentRight = styled('div')<{ $parentStyle: ParentStyle }>(
    ({ $parentStyle }) =>
        mq({
            width:
                $parentStyle === ComponentStyle.customIllustrationChessBlock
                    ? '64%'
                    : '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            [mobileStyles]: {
                alignItems: 'flex-start',
                justifyContent: 'center',
                width: '100%',
            },
        })
);

export const ContentLeft = styled('div')<{ $parentStyle: ParentStyle }>(
    ({ $parentStyle }) =>
        mq({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width:
                $parentStyle === ComponentStyle.customIllustrationChessBlock
                    ? ['100%', '36%', '36%']
                    : ['100%', '50%', '50%'],

            [mobileStyles]: {
                marginTop: '28px',
                alignItems: 'center',
                paddingRight: '0px',
            },

            '& button': {
                [mobileStyles]: {
                    margin: '0 auto',
                },
            },
        })
);

export const Title = styled('h3')<{ $parentStyle: ParentStyle }>(
    ({ theme, $parentStyle }) => {
        return mq({
            ...theme.fonts[
                $parentStyle === ComponentStyle.bigChessBlock ||
                $parentStyle === ComponentStyle.mediumChessBlock ||
                $parentStyle === ComponentStyle.customIllustrationChessBlock
                    ? 'h4_headline'
                    : 'h3_headline'
            ],
            color: theme.colors.primaryText,

            [mobileStyles]: {
                ...theme.fonts.h5_headline,
            },
        });
    }
);

export const Description = styled('div')(({ theme }) => {
    return mq({
        ...theme.fonts.body_2,
        color: theme.colors.primaryText,
        marginTop: '25px',

        '& p': {
            marginTop: '0px!important',
        },

        '& ul': {
            marginTop: '0px!important',
        },
    });
});

export const Wrapper = styled('div')(() =>
    mq({
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: ['54px', '115px', '161px'],
    })
);

export const Content = styled('div')(() =>
    mq({
        width: ['90%', '72.5%', '64.5%'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    })
);

export const H2 = styled('h2')(({ theme }) => {
    return mq({
        ...theme.fonts.h2_headline,
        color: theme.colors.primaryText,

        [tabletStyles]: {
            ...theme.fonts.h3_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.h4_headline,
        },
    });
});

export const TextContainer = styled('div')(() =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        marginLeft: ['0px', '65px', '83px'],

        [mobileStyles]: {
            marginTop: '47px',
            marginBottom: '30px',
        },
    })
);
