import { ComponentColor } from './../../types/constantTypes';
import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';

export const ImgContainer = styled('div')(() =>
    mq({
        position: 'relative',
        height: ['300px', '400px', '400px'],
        width: ['290px', '385px', '385px'],
    })
);

interface ColoredCircleProps {
    $circleColor?: string;
}

export const ColoredCircle = styled(
    'div',
    transientOptions
)<ColoredCircleProps>(({ theme, $circleColor }) =>
    mq({
        position: 'absolute',
        width: ['266px', '353px', '353px'],
        height: ['281px', '374px', '374px'],
        background:
            $circleColor === ComponentColor.secondary
                ? theme.colors.secondaryColor_basic
                : theme.colors.primaryColor,
        borderRadius: '230.5px 20px 227px 240px',
    })
);

export const WhiteCircle = styled('div')(({ theme }) =>
    mq({
        position: 'absolute',
        borderRadius: '226px 50px 227px 240px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
        width: ['282px', '375px', '375px'],
        height: ['288px', '381px', '381px'],
        background: theme.colors.white,
        top: '18px',
        left: '10px',
        padding: '15px',
        overflow: 'hidden',

        [mobileStyles]: {
            top: '15px',
            left: '10px',
        },
    })
);

export const ImageWrapper = styled('div')({
    position: 'relative',
    width: '100%',
    height: '100%',
});

export const Img = styled('img')(() =>
    mq({
        position: 'absolute',
        objectFit: 'cover',
        borderRadius: '207px 15px 200px 208px',

        width: ['245px', '327px', '327px'],
        height: ['256px', '339px', '339px'],

        top: '10%',
        left: '9%',

        [mobileStyles]: {
            top: '31px',
            left: '10%',
        },
    })
);
