import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { mq } from 'theme/mediaQueries';

export const MobileBlock = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '71px',
}));

export const MobileCardWrapper = styled('div')(() => ({
    width: '100%',
    maxWidth: '305px',
    margin: '22px 12px 12px 22px',
    height: '653px',
    position: 'relative',
    zIndex: '100',
}));

interface TypeProps {
    $type: string;
}

export const Type = styled(
    'div',
    transientOptions
)<TypeProps>(({ theme, $type }) => ({
    ...theme.fonts.body_3,
    position: 'absolute',
    width: '45.2%',
    height: '41px',
    background:
        $type === 'partner'
            ? theme.colors.secondaryColor_basic
            : theme.colors.primaryColor,
    color: $type === 'partner' ? theme.colors.primaryText : theme.colors.white,
    borderRadius: '0px 60px 0px 50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '55%',
    zIndex: '300',
    textTransform: 'capitalize',
}));

export const Card = styled('div')(() => ({
    width: '305px',
    maxWidth: '305px',
    height: '653px',
    maxHeight: '653px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '150px 40px 30px 30px',
    zIndex: '200',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '22px',
}));

export const BodyContent = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '28px',
    width: '82%',
}));

export const Title = styled('h4')(({ theme }) => ({
    ...theme.fonts.h6_headline,
    color: theme.colors.primaryText,
}));

export const PosAndCompany = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '9px',
}));

export const Position = styled('p')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.primaryText,
}));

export const Company = styled('p')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.secondaryText,
    whiteSpace: 'pre-wrap',
}));

export const Text = styled('p')(({ theme }) => {
    return mq({
        ...theme.fonts.body_2,
        color: theme.colors.secondaryText,
        marginTop: '20px',
    });
});
