import { ComponentActionsList } from 'types/componentTypes';
import { ButtonsWrapper } from './ActionsList.styled';
import Action from 'components/Action/Action';
import { ComponentType } from 'types/constantTypes';

const ActionsList: React.FC<ComponentActionsList> = ({ value }) => {
    return (
        <ButtonsWrapper>
            {value.actions.map((button, idx) => {
                return (
                    <Action
                        key={idx}
                        value={button}
                        id={`${idx}`}
                        type={ComponentType.action}
                    />
                );
            })}
        </ButtonsWrapper>
    );
};

export default ActionsList;
