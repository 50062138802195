import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles, tabletStyles } from 'types/displayTypeStyles';

export const Background = styled('div')(({ theme }) =>
    mq({
        background: theme.colors.secondaryColor_3,
        borderRadius: ['40px', '200px 0px 200px', '300px 0px 200px'],
        width: '100%',
        minHeight: ['457px', '457px', '642px'],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: ['60px', '103px', '95px'],
        padding: ['20px 51px', '66px 137px', '80px 140px'],
        flexDirection: ['column', 'row', 'row'],
    })
);

export const BodyL = styled('div')(() =>
    mq({
        display: 'flex',
        width: ['100%', '45%', '41%'],
        justifyContent: 'center',
        flexDirection: 'column',
        marginRight: ['0px', '38px', '38px'],

        '& button': {
            [mobileStyles]: {
                margin: '0 auto',
            },
        },
    })
);

export const BodyR = styled('div')(() =>
    mq({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: ['100%', '53%', '50%'],
        height: ['100%', '50%', '100%'],
        position: 'relative',
    })
);

export const Img = styled('img')(() => ({
    maxWidth: '100%',
    height: 'auto',

    [mobileStyles]: {
        marginTop: '52px',
    },
}));

export const Title = styled('h2')(({ theme }) => {
    return mq({
        ...theme.fonts.h2_headline,
        color: theme.colors.primaryText,

        [tabletStyles]: {
            ...theme.fonts.h3_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.h5_headline,
        },
    });
});

export const Description = styled('div')(({ theme }) =>
    mq({
        ...theme.fonts.body_2,
        color: theme.colors.secondaryText,
        marginTop: ['33px', '40px', '40px'],

        '& p': {
            marginTop: '0px!important',
        },

        '& ul': {
            marginTop: '0px!important',
        },
    })
);
