import { CardsWrapper } from './GridCards.styled';
import GridCard from './GridCard';
import type { GridCards } from 'types/componentTypes';

const GridCards: React.FC<GridCards> = ({ cards }) => {
    const GridCards = cards.map((card, i) => {
        const arrayLength = cards.length;

        return (
            <GridCard
                key={card.id}
                {...card}
                lastItem={i === arrayLength - 1}
            />
        );
    });

    return <CardsWrapper className="cards">{GridCards}</CardsWrapper>;
};

export default GridCards;
