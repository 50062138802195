import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { tabletStyles, mobileStyles } from 'types/displayTypeStyles';

export const Body = styled('div')(() =>
    mq({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: ['59px', '82px', '91px'],
    })
);

export const TitleWrapper = styled('div')(() => ({
    maxWidth: '450px',
    textAlign: 'center',
}));

export const Title = styled('h3')(({ theme }) => {
    return mq({
        ...theme.fonts.h3_headline,
        color: theme.colors.primaryText,

        [tabletStyles]: {
            ...theme.fonts.h4_headline,
        },

        [mobileStyles]: {
            ...theme.fonts.h5_headline,
            padding: '0px 25px',
        },
    });
});

export const ImagedTabs = styled('div')(() =>
    mq({
        width: '100%',
        marginTop: '35px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '23px',
        justifyContent: 'center',
        alignItems: 'center',
    })
);

export const RegularTabs = styled('div')(({ theme }) =>
    mq({
        maxWidth: ['auto', '612px', '800px'],
        width: '100%',
        marginTop: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& button:nth-of-type(1)': {
            borderRadius: ['none', '50px 0px 0px 50px', '50px 0px 0px 50px'],
        },

        '& button:nth-last-of-type(1)': {
            borderRadius: ['none', '0px 50px 50px 0px', '0px 50px 50px 0px'],
            borderRight: [
                '',
                `1px solid ${theme.colors.secondaryText}`,
                `1px solid ${theme.colors.secondaryText}`,
            ],
        },
    })
);
