import RichText from 'components/RichText';
import { ComponentHeroValues } from 'types/componentTypes';
import {
    ContentL,
    ContentR,
    Wrapper,
    Title,
    Description,
    Buttons,
    ImgWrapper,
} from './Regular.styled';
import useApiUrl from 'hooks/useApiUrl';
import Image from 'next/image';
import referenceComponentsMap from '../referenceComponentsMap';

const RegularBanner: React.FC<ComponentHeroValues> = ({
    title,
    description,
    references,
    images,
}) => {
    const apiUrl = useApiUrl();
    const withReferences = !!references.length;

    const buttonsBlock = references.map((component) => {
        const componentKey =
            component.type as keyof typeof referenceComponentsMap;

        const Component = referenceComponentsMap[componentKey] as React.FC;

        if (!Component) return null;

        return <Component key={component.id} {...component} />;
    });

    return (
        <Wrapper $withReferences={withReferences}>
            <ContentL $withReferences={withReferences}>
                <div>
                    <Title data-testid="regular">{title}</Title>

                    {description && (
                        <Description>
                            <RichText value={description} />
                        </Description>
                    )}
                </div>

                {withReferences && <Buttons>{buttonsBlock}</Buttons>}
            </ContentL>

            <ContentR>
                {images.map((image, idx) => {
                    if (idx > 0) return null;

                    return (
                        <ImgWrapper key={idx}>
                            <Image
                                src={`${apiUrl}${image.url}`}
                                layout="fill"
                                objectFit='cover'
                                alt={image.title}
                                priority
                            />
                        </ImgWrapper>
                    );
                })}
            </ContentR>
        </Wrapper>
    );
};

export default RegularBanner;
