import RichText from 'components/RichText';
import { ComponentHeroValues } from 'types/componentTypes';
import {
    Wrapper,
    Title,
    Description,
    Buttons,
    Images,
} from './BigIllustration.styled';
import useApiUrl from 'hooks/useApiUrl';
import Image from 'next/image';
import referenceComponentsMap from '../referenceComponentsMap';

const BigIllustration: React.FC<ComponentHeroValues> = ({
    title,
    description,
    references,
    images,
}) => {
    const apiUrl = useApiUrl();
    const withReferences = !!references.length;

    const buttonsBlock = references.map((component) => {
        const componentKey = component.type as keyof typeof referenceComponentsMap;

        const Component = referenceComponentsMap[componentKey] as React.FC;

        if (!Component) return null;

        return <Component key={component.id} {...component} />;
    });

    return (
        <Wrapper $withReferences={withReferences}>
            <div>
                <Title data-testid="big-illustration">{title}</Title>
            </div>

            <Images>
                {images.map((image, i) => {
                    return (
                        <Image
                            key={i}
                            src={`${apiUrl}${image.url}`}
                            alt={image.title}
                            width={image.width}
                            height={image.height}
                            layout="responsive"
                            priority
                        />
                    );
                })}
            </Images>

            {description && (
                <Description>
                    <RichText value={description} />
                </Description>
            )}

            {withReferences && <Buttons>{buttonsBlock}</Buttons>}
        </Wrapper>
    );
};

export default BigIllustration;
